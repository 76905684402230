import { AsyncPipe, NgComponentOutlet, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  effect,
  EnvironmentInjector,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  runInInjectionContext,
  Signal,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '@iot-platform/auth';
import { fromGrids, GridEngineModule, GridsDbActions } from '@iot-platform/grid-engine';
import { IotPlatformDashboardsModule } from '@iot-platform/iot-platform-dashboards';
import { IotMapDisplayMode, IotPlatformMapsModule } from '@iot-platform/iot-platform-maps';
import {
  FilterEngineModule,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  IotToolbarMenuButtonOption,
  SectionHeaderComponent,
  SectionHeaderEvent,
  ToolbarPageType,
  ToolbarV2Module
} from '@iot-platform/iot-platform-ui';
import {
  CommonIndexedPagination,
  FavoriteView,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  Pagination,
  PlatformResponse,
  ToolbarSize,
  UserAccount
} from '@iot-platform/models/common';
import { Dashboard } from '@iot-platform/models/dashboards';
import { I4BGrid, I4BGridData, I4BGridOptions, I4BGridSort } from '@iot-platform/models/grid-engine';
import { fromUserPreferences } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { get, isEqual } from 'lodash';
import { lastValueFrom, map, tap } from 'rxjs';
import { AbstractMasterViewComponent } from '../../components/abstract-master-view.component';
import { FeatureMasterViewDrawerDetailsLayout, FeatureMasterViewSettings } from '../../models/master-view-settings.model';
import { AbstractMasterViewDashboardService } from '../../services/abstract-master-view-dashboard.service';
import { AbstractMasterViewMapService } from '../../services/abstract-master-view-map.service';
import { AbstractMasterViewService } from '../../services/abstract-master-view.service';
import { getMasterViewInjectionTokens } from '../../settings/feature-master-view-settings.provider';

@Component({
  selector: 'shared-feature-master-view',
  standalone: true,
  imports: [
    FilterEngineModule,
    GridEngineModule,
    ToolbarV2Module,
    TranslateModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatToolbarModule,
    NgStyle,
    NgComponentOutlet,
    SectionHeaderComponent,
    IotPlatformDashboardsModule,
    IotPlatformMapsModule,
    NgTemplateOutlet,
    AsyncPipe
  ],
  templateUrl: './master-view.component.html',
  styleUrl: './master-view.component.scss'
})
export class FeatureMasterViewComponent<T> extends AbstractMasterViewComponent<T> implements OnInit, OnDestroy {
  @Input() masterView: string;
  @Output() gridReady: EventEmitter<Signal<I4BGrid<I4BGridOptions, I4BGridData>>> = new EventEmitter<Signal<I4BGrid<I4BGridOptions, I4BGridData>>>();

  @ViewChild('sidenav', { static: false }) sidenav?: MatSidenav;

  selectedRowId: WritableSignal<string> = signal(null);
  userPermissions: WritableSignal<
    {
      key: string;
      value: boolean;
    }[]
  > = signal([]);
  canUpdateBusinessProfile: Signal<boolean> = signal(false);
  grid: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData>> = signal(null);
  staticGrid: Signal<I4BGrid<I4BGridOptions, I4BGridData>>;
  toolbarSize: WritableSignal<string> = signal(ToolbarSize.SMALL);
  permissions: Signal<
    {
      key: string;
      value: boolean;
    }[]
  > = signal([]);

  gridsConfiguration: Signal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }> = signal(null);
  grids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]>;
  dataLoadedByGridSelector: Signal<boolean>;
  gridSortSelector: Signal<I4BGridSort[]>;
  gridSort: WritableSignal<I4BGridSort[]> = signal([]);
  currentUser: Signal<UserAccount>;
  favoriteViewsConfiguration: Signal<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  }>;
  timer: WritableSignal<number> = signal(0);
  // Dashboards
  dashboardsConfiguration: Signal<{
    sortedDashboards: Dashboard[];
    currentDashboard: Dashboard | undefined;
    isDashboardsLoading: boolean;
  }> = signal(null);
  // Map
  mapDisplayMode: Signal<IotMapDisplayMode> = signal(null);
  mapConcept: Signal<string> = signal(null);
  // Toolbar
  pageType: WritableSignal<ToolbarPageType> = signal(ToolbarPageType.GRID);
  selectedGrid: Signal<I4BGrid<I4BGridOptions, I4BGridData> | undefined>;
  selectedGridId: WritableSignal<string> = signal('default');

  toolbarButtons: Signal<(IotToolbarDefaultButton | IotToolbarMenuButton)[]>;
  ToolbarPageType = ToolbarPageType;
  readonly FeatureMasterViewDrawerDetailsLayout = FeatureMasterViewDrawerDetailsLayout;
  protected masterViewService: AbstractMasterViewService;
  protected masterViewDashboardService: AbstractMasterViewDashboardService;
  protected masterViewMapService: AbstractMasterViewMapService;
  protected settings: FeatureMasterViewSettings;
  protected readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  protected readonly authorizationService: AuthorizationService = inject(AuthorizationService);
  protected readonly store: Store = inject(Store);
  protected readonly environmentInjector = inject(EnvironmentInjector);

  private get masterViewEvent() {
    return {
      event: null,
      currentFilters: this.currentFilters(),
      filterEngineOpened: this.filterEngineOpened(),
      grid: this.grid(),
      grids: this.grids(),
      currentFavoriteView: this.currentFavoriteView()
    };
  }

  getRequest(page: number) {
    const grid = this.grid();
    if (!grid) {
      return super.getRequest(page);
    }
    return {
      ...super.getRequest(page),
      concept: grid.masterview.toLowerCase(),
      variables: grid.gridOptions.variableNames,
      tags: grid.gridOptions.tagIds,
      endPoint: grid.gridOptions.endPoint
    };
  }

  // Enable inherit business profile change event only for static grid definition
  handleBusinessProfileChange() {
    if (this.settings?.grid?.staticDefinitionType()) {
      super.handleBusinessProfileChange();
    }
  }

  onTimerValueChanged(value: number) {
    this.timer.set(value);
  }

  ngOnInit() {
    this.initInjectionContext();
    // These two effects need to be initialized before super.ngOnInit()
    // otherwise we call reloadGrid with the default grid and not the selected one
    this.initSelectedGridByMasterview();
    this.initSelectedGridId();
    super.ngOnInit();
    this.initPermissionsEffect();
    this.initGridEffect();
    this.initDataLoadedByGridSelectorEffect();
    this.initLoadedEffect();
    this.initGridSortSelectorEffect();
    this.initGridSortEffect();
    this.initDefaultGridEffect();
    this.initFilterOpenedEffect();
    this.initFavoriteViewButtonsEffect();
    this.initGridPaginationEffect();
    this.onBusinessProfileChange();
    this.handleBusinessProfileChange();
    if (this.settings.grid.staticDefinitionType()) {
      this.initGridDataEffect();
    } else {
      this.initTotalEffect();
    }
    this.gridReady.emit(this.grid);
  }

  onPageChange(pagination: Pagination) {
    if (this.settings.grid.staticDefinitionType()) {
      super.onPageChange(pagination);
    }
    this.toggleDrawerDetails(false);
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    super.onToolbarEvent(event);
    switch (event.type) {
      case IotToolbarDispatchActionType.TOGGLE_PAGE_TYPE.toString():
        this.pageType.set(event.options.pageType);
        break;
      case IotToolbarDispatchActionType.APPLY_GRID.toString():
        this.onApplyGrid(event.options.grid);
        break;
      case IotToolbarDispatchActionType.APPLY_DASHBOARD.toString():
        this.masterViewDashboardService.onToolbarEvent({
          ...this.masterViewEvent,
          event
        });
        break;
      case IotToolbarDispatchActionType.REFRESH_PAGE.toString():
        if (this.masterViewMapService instanceof AbstractMasterViewMapService) {
          this.masterViewMapService.onToolbarEvent({
            ...this.masterViewEvent,
            event
          });
        }
        break;
    }
    this.masterViewService.onToolbarEvent({
      ...this.masterViewEvent,
      event
    });
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent | SectionHeaderEvent): void {
    if (this.settings.drawerDetails.enabled()) {
      if (this.settings.drawerDetails.customOpeningEventType() && this.settings.drawerDetails.customOpeningEventType() === event.type) {
        this.toggleDrawerDetails(true);
      } else if (!this.settings.drawerDetails.customOpeningEventType() && event.type === 'rowClicked') {
        this.toggleDrawerDetails(true);
      }
    }

    this.masterViewService.onMasterViewEngineEvent({
      ...this.masterViewEvent,
      event
    });
  }

  onDispatchGridEvent({ event }): void {
    this.onMasterViewEngineEvent(event);
  }

  onMapEvent(event): void {
    this.masterViewMapService.onMapEvent(event);
  }

  onMapNavigationEvent(event): void {
    this.masterViewMapService.onDispatchMapNavigationEvent(event);
  }

  onApplyFilters(currentFilters: Filter[]): void {
    super.onApplyFilters(currentFilters);
    this.masterViewService.onMasterViewEngineEvent({
      ...this.masterViewEvent,
      event: { type: 'applyFilters', options: { filters: currentFilters } }
    });
  }

  onApplyGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): void {
    this.selectGridAndLoadData(grid.id, grid.masterview, this.currentFilters());
  }

  toggleDrawerDetails(open: boolean) {
    (this.settings.drawerDetails.opened as WritableSignal<boolean>).set(this.settings.drawerDetails.toggle() ? !this.settings.drawerDetails.opened() : open);
  }

  onBackdropClick(): void {
    if (this.settings.drawerDetails.closeOnBackdropClick?.() && this.sidenav) {
      this.sidenav.close();
      (this.settings.drawerDetails.opened as WritableSignal<boolean>).set(false);
    }
  }

  reLoadMasterView() {
    const grid = this.grid();
    const currentFilters = this.currentFilters();
    const mvName = this.settings?.masterViewName();

    if (this.settings.grid.staticDefinitionType()) {
      super.reLoadMasterView();
    } else if (grid?.data) {
      this.pagination.set({
        ...grid.data.response.pagination,
        currentPage: 0 // Force to start from the first page
      } as CommonIndexedPagination);
      const request = this.getRequest(0);
      this.facade.setFilters(request.filters);
      this.store.dispatch(GridsDbActions.loadGridData({ request }));
    } else {
      this.selectGridAndLoadData(this.selectedGridId(), mvName, currentFilters);
    }
    this.toggleDrawerDetails(false);
  }

  sortData(platformResponse: PlatformResponse): PlatformResponse {
    return platformResponse;
  }

  ngOnDestroy() {
    if (this.settings.filterEngine.clearOnDestroy()) {
      this.facade.setFilters([]);
    }
    if (!this.settings.drawerDetails.openedOnDestroy()) {
      this.toggleDrawerDetails(false);
    }
  }

  private initInjectionContext(): void {
    runInInjectionContext(this.environmentInjector, () => {
      const { authFacade, masterViewService, masterViewFacade, settings, masterViewDashboardService, masterViewMapService } = getMasterViewInjectionTokens(
        this.masterView
      );
      this.authFacade = authFacade;
      this.facade = masterViewFacade;
      this.settings = settings;
      this.masterViewService = masterViewService;
      this.masterViewDashboardService = masterViewDashboardService;
      this.masterViewMapService = masterViewMapService;

      this.initToolbarButtonList();
      this.initPageType();
      this.initialFilters = toSignal(this.masterViewService.getInitialFilters().pipe(takeUntilDestroyed(this.destroyRef)));
      this.toolbarButtons = toSignal(this.masterViewService.getToolbarButtons().pipe(takeUntilDestroyed(this.destroyRef)));
      this.permissions = toSignal(this.masterViewService.getPermissions().pipe(takeUntilDestroyed(this.destroyRef)));
      this.currentUser = this.store.selectSignal(fromUserPreferences.getCurrentUser);
      this.canUpdateBusinessProfile = toSignal(this.masterViewService.canUpdateBusinessProfile().pipe(takeUntilDestroyed(this.destroyRef)));
      this.staticGrid = toSignal(this.masterViewService.getStaticGridDefinition().pipe(takeUntilDestroyed(this.destroyRef)));
      this.gridsConfiguration = toSignal(this.masterViewService.getGridConfiguration().pipe(takeUntilDestroyed(this.destroyRef)));
      this.grids = toSignal(this.masterViewService.getGrids().pipe(takeUntilDestroyed(this.destroyRef)));
      this.favoriteViewsConfiguration = toSignal(this.masterViewService.getFavoriteViewsConfiguration().pipe(takeUntilDestroyed(this.destroyRef)));
      this.currentFavoriteView$ = this.masterViewService.getFavoriteViewsConfiguration().pipe(
        map((conf) => conf?.currentFavoriteView),
        takeUntilDestroyed(this.destroyRef)
      );
      this.currentFavoriteView = toSignal(this.currentFavoriteView$);
      this.dashboardsConfiguration = this.masterViewDashboardService?.getDashboardConfiguration?.();
      this.mapDisplayMode = this.masterViewMapService?.getMapDisplayMode?.();
      this.mapConcept = this.masterViewMapService?.getMapConcept?.();
    });
  }

  private async onBusinessProfileChange() {
    await lastValueFrom(
      this.authFacade.isBusinessProfileChanged$.pipe(
        tap((isBusinessProfileChanged: boolean) => {
          if (isBusinessProfileChanged) {
            this.masterViewService.onBusinessProfileChange(this.masterViewEvent);
          }
        })
      )
    );
  }

  private initFavoriteViewButtonsEffect() {
    effect(
      () => {
        const currentUser = this.currentUser();
        const currentFilters = this.currentFilters();
        const currentFavoriteView: FavoriteView | null = this.currentFavoriteView();
        if (currentUser && currentUser.id) {
          this.toolbarButtonList.update((toolbarButtonList: IotToolbarDefaultButton[]) => {
            toolbarButtonList.forEach((button) => {
              if (button instanceof IotToolbarMenuButton) {
                button.menuOptions.forEach((option: IotToolbarMenuButtonOption) => {
                  if (option.dispatchAction.type === IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW.toString()) {
                    option.disableOption = isEqual(currentFilters.sort(), currentFavoriteView?.filters?.sort());
                  }
                  if (
                    option.dispatchAction.type === IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW.toString() ||
                    option.dispatchAction.type === IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW.toString()
                  ) {
                    if (!this.canUpdateBusinessProfile()) {
                      if (currentFavoriteView?.shared) {
                        option.disableOption = true;
                        return;
                      }
                      option.disableOption = !currentFavoriteView?.filters || currentFavoriteView?.owner !== currentUser.id;
                    } else {
                      option.disableOption = !currentFavoriteView?.filters;
                    }
                  }
                });
              }
            });
            return [...toolbarButtonList];
          });
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initFilterOpenedEffect() {
    effect(
      () => {
        const currentUser = this.currentUser();
        if (currentUser) {
          this.filterEngineOpened.set(get(currentUser, ['preferences', 'filterEngineOpenByDefault'], false));
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initDefaultGridEffect() {
    effect(
      () => {
        const businessProfile = this.businessProfile();
        const grids = this.grids();
        const grid = this.grid();
        const currentFilters = this.currentFilters();
        if (businessProfile && grids && !grid?.data) {
          this.selectGridAndLoadData(this.selectedGridId(), this.settings?.masterViewName(), currentFilters);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initSelectedGridByMasterview() {
    effect(
      () => {
        const mvName = this.settings?.masterViewName();
        if (mvName) {
          this.selectedGrid = this.store.selectSignal(fromGrids.selectSelectedGridByMasterView(mvName));
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initSelectedGridId() {
    effect(
      () => {
        const selectedGrid = this.selectedGrid();
        this.selectedGridId.set(selectedGrid?.id ?? 'default');
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initPermissionsEffect() {
    effect(
      () => {
        const privileges = this.authFacade.privileges();
        if (privileges) {
          const permissions = this.permissions();
          this.userPermissions.set(permissions);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initPageType() {
    this.pageType.set(this.settings.toolbar.pageTypeOptions.defaultPageType());
  }

  private initToolbarButtonList() {
    effect(
      () => {
        const toolbarButtons = this.toolbarButtons();
        if (this.toolbarButtons().length > 0) {
          this.toolbarButtonList.set([...toolbarButtons]);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initGridEffect() {
    effect(
      () => {
        if (this.settings.grid.staticDefinitionType()) {
          const grid = this.staticGrid();
          this.grid.set(grid);
        } else {
          const gridsConfiguration = this.gridsConfiguration();
          this.grid.set(get(gridsConfiguration, 'currentGrid'));
        }
      },
      { injector: this.injector, allowSignalWrites: true, manualCleanup: true }
    );
  }

  private initDataLoadedByGridSelectorEffect() {
    effect(
      () => {
        const grid = this.grid();
        if (grid) {
          this.dataLoadedByGridSelector = this.store.selectSignal(fromGrids.getDataLoadedByGrid(grid.id));
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initGridPaginationEffect() {
    effect(
      () => {
        const grid = this.grid();
        if (!this.settings.grid.staticDefinitionType() && grid?.data) {
          this.pagination.set({
            ...grid.data.response.pagination
          } as CommonIndexedPagination);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initLoadedEffect() {
    effect(
      () => {
        const grid = this.grid();
        if (grid) {
          const dataLoadedByGrid = this.dataLoadedByGridSelector();
          this.loaded.set(!!dataLoadedByGrid);
        } else {
          this.loaded.set(false);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initGridSortSelectorEffect() {
    effect(
      () => {
        const grid = this.grid();
        if (grid) {
          this.gridSortSelector = this.store.selectSignal(fromGrids.getSortByGrid(grid.id));
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initGridSortEffect() {
    effect(
      () => {
        const grid = this.grid();
        if (grid) {
          const gridSort = this.gridSortSelector();
          this.gridSort.set(gridSort);
        } else {
          this.gridSort.set([]);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initTotalEffect() {
    effect(
      () => {
        const gridsConfiguration = this.gridsConfiguration();
        this.totalItems.set(get(gridsConfiguration, ['currentGrid', 'data', 'response', 'pagination', 'total'], 0));
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initGridDataEffect() {
    effect(
      () => {
        const platformResponse = this.platformResponse();
        this.grid.update((grid) => {
          grid.data.response.data = [...platformResponse.data];
          grid.data.response.pagination = this.pagination();
          return { ...grid };
        });
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private selectGridAndLoadData(gridId: string, masterview: string, filters: Filter[]) {
    this.store.dispatch(GridsDbActions.selectGridAndLoadData({ gridId, masterview, filters }));
  }
}
