import { Component, effect, input, OnInit, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { Filter } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncAutocompleteComponent } from '../async-autocomplete/async-autocomplete.component';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinner,
    MatTooltip,
    MatCheckboxModule
  ],
  selector: 'iot-platform-ui-async-autocomplete-multiple-selects',
  templateUrl: './async-autocomplete-multiple-selects.component.html',
  styleUrls: ['./async-autocomplete-multiple-selects.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'mat-mdc-select-bp-overlay-pane' }
    }
  ]
})
export class AsyncAutocompleteMultipleSelectsComponent extends AsyncAutocompleteComponent implements OnInit {
  selectedFilters: WritableSignal<any> = signal([]);
  filtersSize: WritableSignal<number> = signal(0);

  currentFilters = input<Filter[]>([]);
  currentFiltersSize = input<number>(0);
  multiSelect = input<boolean>(true);
  maxFilters = input<number>(20);
  type = input<string>('');

  ngOnInit() {
    super.ngOnInit();
    this.initFilterSizeEffect();
    this.initSelectedFiltersWhenMultiSelect();
    this.initSelectedFiltersWhenStandaloneMode();
    this.initFilteredDataEffect();
  }

  toggleSelection(item: any): void {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedFilters.update((values) => [...values, item]);
      this.filtersSize.set(this.filtersSize() + 1);
    } else {
      const i = this.selectedFilters().findIndex((option: any) => option[this.displayKey()] === item[this.displayKey()]);
      this.selectedFilters.update((values) => [...values.splice(i, 1)]);
      this.filtersSize.set(this.filtersSize() - 1);
    }
    this.selectionChanged.emit(item);
  }

  private initSelectedFiltersWhenMultiSelect() {
    effect(
      () => {
        const multiSelect = this.multiSelect();
        if (multiSelect) {
          const data = this.data();
          if (data) {
            const currentFilters = this.currentFilters();
            this.selectedFilters.set([]);
            data.forEach((option: any) => {
              if (currentFilters.find((filter: Filter) => (option.id ? option.id === filter.value : option.key === filter.value))) {
                option.selected = true;
                this.selectedFilters.update((values) => [...values, option]);
              } else {
                option.selected = false;
              }
            });
          }
        }
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  private initSelectedFiltersWhenStandaloneMode() {
    effect(
      () => {
        const standaloneMode = this.standaloneMode();
        const multiSelect = this.multiSelect();
        const currentFilters = this.currentFilters();
        const data = this.data();
        if (standaloneMode && multiSelect && currentFilters.length) {
          if (data) {
            data.forEach((option: any) => {
              if (currentFilters.find((filter: Filter) => option.id === filter.value)) {
                option.selected = true;
                this.selectedFilters.update((values) => [...values, option]);
              }
            });
          }
        }
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  private initFilteredDataEffect() {
    effect(
      () => {
        const multiSelect = this.multiSelect();
        const type = this.type();
        const data = this.data();
        if (multiSelect && type === 'tag' && data) {
          this.initFilteredData();
        }
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  private initFilterSizeEffect() {
    effect(
      () => {
        const currentFiltersSize = this.currentFiltersSize();
        this.filtersSize.set(currentFiltersSize);
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }
}
