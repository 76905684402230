<div data-cy="grid-engine-icon-cell">
  @if (isIcon) {
    <mat-icon
      [matTooltipClass]="'regular-tooltip'"
      [matTooltip]="
        (params?.colDef?.cellRendererParams?.cellOptions?.tooltip
          ? params?.colDef?.cellRendererParams?.cellOptions?.tooltip + '.' + params?.value?.toLowerCase()
          : ''
        ) | translate
      "
      matTooltipPosition="above"
      [ngClass]="['icon-' + params.value, 'table-icon-content']"
      [svgIcon]="value?.toLowerCase()"
    ></mat-icon>
  } @else {
    <!-- Used for filter custom filter renderer -->
    <span>{{ value }}</span>
  }
</div>
