import { Injectable, Signal } from '@angular/core';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { CommonApiRequest, FavoriteView, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { DeviceEvent, Log } from '@iot-platform/models/i4b';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getDefaultDeviceEventsGrid, getDeviceEventsGrids, selectItemInGrid } from '../../../../../../grid-engine/src/lib/components/state/reducers';
import { DeviceEventsCommentsActions, DeviceEventsUiActions } from './actions';
import * as fromDeviceEvents from './reducers';

@Injectable()
export class DeviceEventsApi {
  grid$ = this.store.select(getDefaultDeviceEventsGrid);
  grids$ = this.store.select(getDeviceEventsGrids);
  favoriteViews$ = this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewDeviceEvents);
  currentFavoriteView$ = this.store.select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewDeviceEvents);
  currentFilters$ = this.store.select(fromFavoriteViews.getFiltersForMasterViewDeviceEvents);
  loadingFavoriteViews$ = this.store.select(fromFavoriteViews.getLoading);
  deviceEventsLoaded$ = this.store.select(fromDeviceEvents.getDeviceEventsLoaded);
  site$ = this.store.select(fromDeviceEvents.getSite);
  siteLoaded$ = this.store.select(fromDeviceEvents.getSiteLoaded);
  asset$ = this.store.select(fromDeviceEvents.getAsset);
  assetLoaded$ = this.store.select(fromDeviceEvents.getAssetLoaded);
  assetVariable$ = this.store.select(fromDeviceEvents.getAssetVariable);
  assetVariableLoaded$ = this.store.select(fromDeviceEvents.getAssetVariableLoaded);
  device$ = this.store.select(fromDeviceEvents.getDevice);
  deviceLoaded$ = this.store.select(fromDeviceEvents.getDeviceLoaded);
  deviceVariable$ = this.store.select(fromDeviceEvents.getDeviceVariable);
  deviceVariableLoaded$ = this.store.select(fromDeviceEvents.getDeviceVariableLoaded);

  deviceEventComments$: Observable<Log[]> = this.store.select(fromDeviceEvents.selectAllDeviceEventComments);
  deviceEventComments: Signal<Log[]> = this.store.selectSignal(fromDeviceEvents.selectAllDeviceEventComments);
  deviceEventCommentsLoading: Signal<boolean> = this.store.selectSignal(fromDeviceEvents.selectDeviceEventCommentsLoading);
  deviceEventCommentsLoaded$: Observable<boolean> = this.store.select(fromDeviceEvents.selectDeviceEventCommentsLoaded);
  deviceEventCommentsLoaded: Signal<boolean> = this.store.selectSignal(fromDeviceEvents.selectDeviceEventCommentsLoaded);

  tags$ = this.store.select(fromDeviceEvents.getTags);
  tagsLoaded$ = this.store.select(fromDeviceEvents.getTagsLoaded);
  status$ = this.store.select(fromDeviceEvents.getStatus);
  deviceEventFavoriteViewsConfiguration$ = this.store.select(fromFavoriteViews.selectDeviceEventFavoriteViewsConfiguration);

  constructor(private readonly store: Store) {}

  selectedDeviceEvent$ = (gridId: string, itemId: string) => this.store.select(selectItemInGrid(gridId, itemId));

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'device-events' }));
  }

  loadDeviceEvents(request: CommonApiRequest) {
    this.store.dispatch(
      GridsDbActions.loadGridData({
        request: { concept: 'device-events', ...request }
      })
    );
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadSiteById({ siteId }));
  }

  loadDeviceById(deviceId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadDeviceById({ deviceId }));
  }

  loadDeviceVariableById(deviceVariableId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadDeviceVariableById({ deviceVariableId }));
  }

  loadAssetById(assetId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadAssetById({ assetId }));
  }

  loadAssetVariableById(assetVariableId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadAssetVariableById({ assetVariableId }));
  }

  loadTagsByDeviceEventId(deviceEventId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadTagsByDeviceEventId({ deviceEventId }));
  }

  loadEventDetailPopupDataByDeviceEvent(deviceEvent: DeviceEvent) {
    this.loadSiteById(deviceEvent.context.site.id);
    this.loadAssetById(deviceEvent.context.asset.id);
    this.loadAssetVariableById(deviceEvent.context.assetVariable.id);
    this.loadDeviceById(deviceEvent.context.device.id);
    this.loadDeviceVariableById(deviceEvent.context.deviceVariable.id);
  }

  bulkUpdateStatusByGridAndDeviceEvent(grid, deviceEvents: DeviceEvent[], status: string) {
    deviceEvents.forEach((dEvent) => {
      const updatedEvent = {
        ...dEvent,
        status
      };
      this.store.dispatch(GridsDbActions.updateItemInGridData({ gridId: grid.id, item: updatedEvent, concept: 'device-events' }));
    });
  }

  updateStatusByDeviceEventId(deviceEventIds: string[], status: string) {
    this.store.dispatch(DeviceEventsUiActions.bulkUpdateStatusByDeviceEventId({ deviceEventIds, status }));
  }

  bulkUpdateStatusByDeviceEventId(deviceEventIds: string[], status: string) {
    this.store.dispatch(DeviceEventsUiActions.newBulkUpdateStatusByDeviceEventId({ deviceEventIds, status }));
  }

  saveTableState(tableState: { selected: DeviceEvent; checked: DeviceEvent[] }) {
    this.store.dispatch(DeviceEventsUiActions.saveTableState({ tableState }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (data.favoriteView.shared && !!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteViewFromMV({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteViewFromMV({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (data.favoriteView.shared && !!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteViewFromMV({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteViewFromMV({ favoriteView: data.favoriteView }));
    }
  }

  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'device-events', favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'device-events', filters }));
  }

  loadComments(deviceEvent: DeviceEvent) {
    this.store.dispatch(DeviceEventsCommentsActions.loadComments({ deviceEvent }));
  }

  addComment(deviceEvent: DeviceEvent, comment: string) {
    this.store.dispatch(DeviceEventsCommentsActions.addComment({ deviceEvent, comment }));
  }

  editComment(deviceEventId: string, comment: Log): void {
    this.store.dispatch(DeviceEventsCommentsActions.editComment({ deviceEventId, comment }));
  }

  deleteComment(deviceEvent: DeviceEvent, commentId: string): void {
    this.store.dispatch(DeviceEventsCommentsActions.deleteComment({ deviceEvent, commentId }));
  }
}
