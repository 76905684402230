import { Sort } from '@angular/material/sort';
import { CommonApiRequest, FavoriteView, Filter, gridSortModel, Product, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetCommandResponse, CommandType, I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';
import { I4BActionHelper } from '../../../../helpers/ngrx';

class AssetsActionHelper extends I4BActionHelper<Asset> {}
const assetsActionHelper = new AssetsActionHelper();

export const AssetsActions = createActionGroup({
  source: 'Assets',

  events: {
    ...assetsActionHelper.getDefaultEvents(),
    'Update One from MV': props<{ assetToUpdate: Asset; request: CommonApiRequest }>(),
    'Update One from MV Success': props<{ asset: Asset }>(),
    'Update One from MV Failure': props<{ error: any }>(),
    'Add One by Template Id': props<{ assetTemplateId: string; siteId: string }>(),
    'Add One by Template Id Success': props<{ added: Asset }>(),
    'Add One by Template Id Failure': props<{ error: any }>(),

    'Get Tags': props<{ assetId: string }>(),
    'Get Tags Success': props<{ tags: TagCategory[] }>(),
    'Get Tags Failure': props<{ error: any }>(),
    'Update Tags By Asset Id': props<{ assetId: string; tags: TagCategory[] }>(),
    'Update Tags By Asset Id Success': props<{ tags: TagCategory[] }>(),
    'Update Tags By Asset Id Failure': props<{ error: any }>(),
    'Bulk Add or Remove Tag': props<{ isAddition: boolean; tagLabelId: string; assetsIds: string[] }>(),
    'Bulk Add Tag - ': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Add Tag Failure': props<{ error: any }>(),
    'Bulk Remove Tag - ': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Remove Tag Failure': props<{ error: any }>(),

    'Open Asset Detail': props<{ assetId: string }>(),
    'Open Asset Detail Success': props<{ asset: Asset }>(),
    'Open Asset Detail Failure': props<{ error: any }>(),

    'Get Site By Id': props<{ siteId: string }>(),
    'Get Site By Id Success': props<{ site: Site }>(),
    'Get Site By Id Failure': props<{ error: any }>(),
    'Assign Product to Asset': props<{ product: Product; asset: Asset }>(),
    'Assign Product to Asset Success': props<{ asset: Asset }>(),
    'Assign Product to Asset Failure': props<{ error: any }>(),
    'Remove Product from Asset': props<{ asset: Asset; product: Product }>(),
    'Remove Product from Asset Success': props<{ product: Product; asset: Asset }>(),
    'Remove Product from Asset Failure': props<{ error: any }>(),

    'Send Command': props<{ asset: Asset; command: { command: CommandType } }>(),
    'Send Command Success ': props<{ response: AssetCommandResponse }>(),
    'Send Command Failure': props<{ error: any }>(),
    'Bulk Send Command': props<{ assetsIds: string[]; command: { command: CommandType } }>(),
    'Bulk Send Command Success ': props<{ response: AssetCommandResponse }>(),
    'Bulk Send Command Failure': props<{ error: any }>(),

    'Save Current Favorite View': props<{ currentFavoriteView: FavoriteView }>(),
    'Set Filters': props<{ filters: Filter[] }>(),

    'Load MV Assets Settings': props<{ settingName: string }>(),
    'Load MV Assets Settings Success': props<{ settings: any }>(),
    'Load MV Assets Settings Failure': props<{ error: any }>(),
    'Save MV Assets Settings': props<{ name: string; values: any }>(),
    'Save MV Assets Settings Success': props<{ settings: any }>(),
    'Save MV Assets Settings Failure': props<{ error: any }>(),

    'Save Initial sort': props<{ initialSort: gridSortModel[] | Sort }>()
  }
});
