<form [formGroup]="form" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
  @if (data()?.autocomplete && !data()?.multiSelect) {
    <iot-platform-ui-async-autocomplete
      (selectionChanged)="onSelectionChange($event)"
      [data]="dynamicList()"
      [displayKey]="data()?.selectByProperty"
      [displaySearchIcon]="false"
      [displayWrapper]="data()?.displayWrapper"
      [filterKey]="data()?.skipAutoCompleteFilterCriteria ? null : data()?.selectByProperty"
      [minLength]="1"
      [placeholder]="data()?.placeholder"
      [showSpinner]="showLoader()"
      [standaloneMode]="true"
      [tooltip]="data()?.tooltip"
    />
  }
  @if (data()?.autocomplete && data()?.multiSelect) {
    <iot-platform-ui-async-autocomplete-multiple-selects
      (selectionChanged)="onSelectionChange($event)"
      [clearOnSelect]="true"
      [currentFilters]="currentFilters()"
      [currentFiltersSize]="currentFiltersSize()"
      [data]="dynamicList()"
      [displayKey]="data()?.selectByProperty"
      [displaySearchIcon]="false"
      [displayWrapper]="data()?.displayWrapper"
      [filterKey]="data()?.skipAutoCompleteFilterCriteria ? null : data()?.selectByProperty"
      [minLength]="1"
      [multiSelect]="data()?.multiSelect"
      [placeholder]="data()?.placeholder"
      [showSpinner]="showLoader()"
      [standaloneMode]="true"
    />
  }
  @if (!data()?.autocomplete) {
    <div>
      <mat-form-field class="select-field" color="accent">
        <mat-label fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
          <span class="filter-label" fxFlex="90">{{ data()?.placeholder ?? data()?.criteriaLabel | translate }}</span>
          @if (showLoader()) {
            <mat-progress-spinner [diameter]="16" color="accent" fxFlex="10" mode="indeterminate" />
          }
        </mat-label>

        @if (data()?.multiSelect) {
          <mat-select
            (selectionChange)="onSelectionChange()"
            [compareWith]="compareFn"
            multiple
            data-cy="iot-platform-ui-dynamic-list-field-single-select"
            formControlName="select"
          >
            @if (!showLoader()) {
              @for (option of dynamicList(); track option) {
                <mat-option [disabled]="currentFiltersSize() >= maxFilters()" [value]="option"
                            data-cy="iot-platform-ui-dynamic-list-field-single-select-option"
                >{{ getOptionToDisplay(option) }}
                </mat-option>
              }
            }
          </mat-select>
        } @else {
          <mat-select
            (selectionChange)="onSelectionChange()"
            [compareWith]="compareFn"
            data-cy="iot-platform-ui-dynamic-list-field-single-select"
            formControlName="select"
          >
            @if (!showLoader()) {
              @for (option of dynamicList(); track option) {
                <mat-option [disabled]="currentFiltersSize() >= maxFilters()" [value]="option"
                            data-cy="iot-platform-ui-dynamic-list-field-single-select-option"
                >{{ getOptionToDisplay(option) }}
                </mat-option>
              }
            }
          </mat-select>
        }
      </mat-form-field>
    </div>
  }
</form>
