<iot-platform-ui-toolbar-v2
  (dispatchToolbarEvent)="onToolbarEvent($event)"
  [buttonList]="businessProfilesButtonList"
  [isDataLoaded]="!(businessProfilesPendingStatus$ | async)"
  [name]="'ADMIN.BUSINESS_PROFILES.TOOLBAR_TITLE' | translate"
  [size]="toolbarSize"
  [total]="total$ | async"
>
</iot-platform-ui-toolbar-v2>
<div class="bp-page">
  <div class="full-height-with-overflow" fxFlex="30">
    @if (!(businessProfilesPendingStatus$ | async) || (selectedBusinessProfile$ | async)) {
      <iot4bos-backoffice-ui-filter-bp-by-name [searchString]="searchString$ | async" (filter)="filter($event)"></iot4bos-backoffice-ui-filter-bp-by-name>
    }
    <iot4bos-backoffice-ui-business-profiles-list
      (selectBusinessProfile)="selectBusinessProfile($event)"
      [navigateOnClick]="true"
      [profiles]="filteredProfiles$ | async"
      [selectedBusinessProfile]="selectedBusinessProfile$ | async"
    ></iot4bos-backoffice-ui-business-profiles-list>
  </div>
  <div class="full-width">
    @if (!selectedBusinessProfile) {
      <span fxFlexFill class="regular-background no-profile" fxLayoutAlign="center center">{{
        'ADMIN.BUSINESS_PROFILES.DETAIL.SELECT_A_BUSINESS_PROFILES' | translate | uppercase
      }}</span>
    } @else {
      <div fxFlexFill class="regular-background full-height p-20" fxLayout="column">
        <mat-toolbar color="accent" fxLayoutAlign="space-between center">
          <h2 fxLayout="row" fxLayoutAlign="start center">
            {{ selectedBusinessProfile?.name }}
            <span class="entity-name" fxLayoutAlign="start center" (click)="navigateToSelectedOrganization(selectedBusinessProfile?.entityId)">
              {{ selectedBusinessProfile?.entityName }}
            </span>
          </h2>
          <span fxFlex></span>
          @if (canUpdateBusinessProfile) {
            <button
              [matTooltip]="'ADMIN.BUSINESS_PROFILES.DETAIL.EDIT_BUSINESS_PROFILE_NAME' | translate"
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
              mat-icon-button
              (click)="editBusinessProfile(selectedBusinessProfile)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          }
          <div [matTooltip]="getDeleteTooltip() | async" matTooltipClass="regular-tooltip" matTooltipPosition="below">
            @if (canDeleteBusinessProfile) {
              <button
                class="icon-disabled-white"
                [disabled]="isDeletePossible() | async"
                mat-icon-button
                (click)="deleteBusinessProfile(selectedBusinessProfile)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            }
          </div>
        </mat-toolbar>
        <mat-tab-group fxFlex mat-align-tabs="left" mat-stretch-tabs color="accent">
          <mat-tab label="{{ 'ADMIN.BUSINESS_PROFILES.TABS.INFO' | translate | uppercase }}">
            <ng-template matTabContent>
              <iot4bos-backoffice-ui-business-profiles-detail
                [businessProfile]="selectedBusinessProfile$ | async"
                [users]="members$ | async"
                [roles]="roles$ | async"
                [rolesPendingStatus]="rolesPendingStatus$ | async"
                [membersPendingStatus]="membersPendingStatus$ | async"
                [canCreateBusinessProfile]="canCreateBusinessProfile"
                [canUpdateBusinessProfile]="canUpdateBusinessProfile"
                (saveProfile)="onUpdateProfile($event)"
                (addMemberToProfile)="onAddMembersToProfile()"
                (addRoleToProfile)="onAddRolesToProfile()"
              >
              </iot4bos-backoffice-ui-business-profiles-detail>
            </ng-template>
          </mat-tab>
          <mat-tab label="{{ 'ADMIN.BUSINESS_PROFILES.TABS.TAGS' | translate | uppercase }}">
            <ng-template matTabContent>
              <iot4bos-backoffice-ui-business-profile-tags
                [tags]="tags$ | async"
                [tagsPendingStatus]="tagsPendingStatus$ | async"
                [canUpdateBusinessProfile]="canUpdateBusinessProfile"
                (addTagsToProfile)="onAddTagsToProfile($event)"
              >
              </iot4bos-backoffice-ui-business-profile-tags>
            </ng-template>
          </mat-tab>
          @if (displayFavoriteViewsTab) {
            <mat-tab class="favorite-views-tab">
              <ng-template mat-tab-label>
                {{ 'ADMIN.BUSINESS_PROFILES.TABS.FAVORITE_VIEW_MANAGER' | translate | uppercase }}
                @if (favoriteViewsPendingStatus$ | async) {
                  <mat-progress-spinner [diameter]="14" mode="indeterminate" color="accent" style="margin-left: 8px"></mat-progress-spinner>
                } @else {
                  <span style="width: 20px"></span>
                }
              </ng-template>
              <ng-template matTabContent>
                <div>
                  <mat-card-title>
                    <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
                      <h2 class="panel-title" fxFlex fxLayout="row" fxLayoutGap="10px">
                        <span> {{ 'ADMIN.BUSINESS_PROFILES.TABS.FAVORITE_VIEW_MANAGER' | translate | uppercase }} </span>
                      </h2>
                      @if (canUpdateBusinessProfile) {
                        <button
                          [matTooltip]="'ADMIN.BUSINESS_PROFILES.DETAIL.ADD_FAVORITE_VIEW' | translate"
                          matTooltipClass="regular-tooltip"
                          matTooltipPosition="below"
                          mat-icon-button
                          class="regular-round-button"
                          [matMenuTriggerFor]="menu"
                        >
                          <mat-icon>add</mat-icon>
                        </button>
                      }
                      <mat-menu #menu="matMenu">
                        @for (masterView of masterViews; track masterView) {
                          <button mat-menu-item (click)="onAddFavoriteView(masterView)" class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                            <mat-icon [svgIcon]="masterView" [color]="'#394C5A'"></mat-icon>
                            <span>{{ 'IOT_DICTIONARY.' + masterView | translate }}</span>
                          </button>
                        }
                      </mat-menu>
                    </mat-toolbar>
                  </mat-card-title>
                  <i4b-table-engine-master-view-engine
                    [masterViewType]="'favorite-view-manager'"
                    [useExternalData]="true"
                    [useFullyLoadedDataset]="true"
                    [data]="sharedFavoriteViewsByBusinessProfile$ | async"
                    [userPermissions]="userPermissions"
                    (dispatchMasterViewEngineEvent)="onFavoriteViewManagerAction($event)"
                    (pageChange)="onFVPageChange($event)"
                  >
                  </i4b-table-engine-master-view-engine>
                </div>
              </ng-template>
            </mat-tab>
          }
          @if (displayGridsTab) {
            <mat-tab class="grids-tab" label="{{ 'ADMIN.BUSINESS_PROFILES.TABS.GRID_MANAGER' | translate | uppercase }}">
              <ng-template matTabContent>
                <iot4bos-backoffice-ui-grid-manager
                  [grids]="sharedGridsByBusinessProfile$ | async"
                  [userPermissions]="userPermissions"
                  [masterViewList]="masterViews"
                  (addGrid)="onAddGrid($event)"
                  (deleteGrid)="onDeleteGrid($event)"
                  (updateGrid)="onUpdateGrid($event)"
                  (changeGridPage)="onGridPageChange($event)"
                >
                </iot4bos-backoffice-ui-grid-manager>
              </ng-template>
            </mat-tab>
          }
        </mat-tab-group>
      </div>
    }
  </div>
</div>
