import { NgClass, NgComponentOutlet, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  Injector,
  input,
  model,
  output,
  signal,
  Signal,
  Type,
  untracked,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { FavoriteFiltersByConcept, FavoriteView, Filter, UserAccount, UserPreferences } from '@iot-platform/models/common';
import { NgxInitModule } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash';
import { Observable, switchMap } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { ChipComponent } from '../chip/chip.component';

import { FilterComponentFactory } from './filter-component-factory';
import { ManageFavoriteFiltersPopupComponent } from './manage-favorite-filters-popup/manage-favorite-filters-popup.component';
import { FilterConfiguration, FilterCriteriaConfiguration, FilterEngineMode } from './models';
import { FilterEngineSettingsService } from './services/filter-engine-settings.service';
import { FilterEngineService } from './services/filter-engine.service';

export const MAX_FILTERS = 20;

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatButtonToggleModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltip,
    MatMenuModule,
    UpperCasePipe,
    NgClass,
    MatChipsModule,
    ChipComponent,
    MatButtonModule,
    FormsModule,
    NgComponentOutlet,
    NgTemplateOutlet,
    NgxInitModule
  ],
  selector: 'iot-platform-ui-filter-engine',
  templateUrl: './filter-engine.component.html',
  styleUrls: ['./filter-engine.component.scss']
})
export class FilterEngineComponent {
  /**
   * Inputs
   */
  mode = model(FilterEngineMode.CLASSIC);
  expanded = input<boolean>(false);
  readonly = input<boolean>(false);
  displayActionButtons = input<boolean>(false);
  displayManageFavoriteFilters = input<boolean>(true);
  withFavoriteFilters = input<boolean>(false);
  masterView = input<string>();
  clearAppliedFilters = input<boolean>(false);
  currentFavoriteView = input<FavoriteView | null>(null);
  currentFilters = input<Filter[]>([]);
  maxFilters = MAX_FILTERS;
  /**
   * Outputs
   */
  applyFilters = output<Filter[]>();
  /**
   * Local variables
   */
  FILTER_CRITERIA_BUTTON_DEFAULT_TITLE = 'FILTER_ENGINE.FILTER_CRITERIA_DEFAULT_TITLE';
  filterCriteriaButtonTitle: WritableSignal<string> = signal(this.FILTER_CRITERIA_BUTTON_DEFAULT_TITLE);
  workingFilters: WritableSignal<Filter[]> = signal([]);
  currentFiltersNotHidden: Signal<Filter[]> = computed(() => {
    const workingFilters = this.workingFilters();
    return workingFilters.filter((f) => !f.isHidden && f.criteriaKey !== 'includeSubEntities');
  });
  favoriteFields: WritableSignal<
    {
      component: Type<any>;
      inputs: any;
    }[]
  > = signal([]);
  classicFields: WritableSignal<
    {
      component: Type<any>;
      inputs: any;
    }[]
  > = signal([]);
  FilterEngineMode = FilterEngineMode;
  displayResetToFavoriteViewFiltersButton: Signal<boolean> = computed(() => {
    const workingFilters = this.workingFilters();
    const currentFavoriteView = this.currentFavoriteView();
    if (!!workingFilters && !!currentFavoriteView) {
      return !isEqual(workingFilters.sort(), currentFavoriteView?.filters?.sort());
    } else {
      return false;
    }
  });
  /**
   * Injectables
   */
  protected dialog: MatDialog = inject(MatDialog);
  protected injector = inject(Injector);
  protected destroyRef: DestroyRef = inject(DestroyRef);
  protected filterEngineService: FilterEngineService = inject(FilterEngineService);
  protected filterEngineSettingsService: FilterEngineSettingsService = inject(FilterEngineSettingsService);
  userPreferences: Signal<UserPreferences> = this.filterEngineSettingsService.userPreferences;
  user: Signal<UserAccount> = this.filterEngineSettingsService.account;
  protected filterComponentFactory: FilterComponentFactory = inject(FilterComponentFactory);
  private categories$: Observable<FilterCriteriaConfiguration[]> = toObservable(this.masterView).pipe(
    filter((name) => !!name),
    debounceTime(100),
    switchMap((mvName: string) => this.filterEngineService.getFilterCriteriaByConcept(mvName)),
    tap(() => this.resetFilterCriteriaButtonTitle()),
    takeUntilDestroyed(this.destroyRef)
  );
  categories: Signal<FilterCriteriaConfiguration[]> = toSignal(this.categories$) as Signal<FilterCriteriaConfiguration[]>;

  constructor() {
    this.initFavoriteFiltersEffect();
    this.initFavoriteViewEffect();
    this.initFilterEffect();
    this.initClearFiltersEffect();
  }

  resetFilterCriteriaButtonTitle(): void {
    this.filterCriteriaButtonTitle.set(this.FILTER_CRITERIA_BUTTON_DEFAULT_TITLE);
  }

  addField(criteria: any) {
    const currentFiltersNotHidden = this.currentFiltersNotHidden();
    const mode = this.mode();
    const filters = this.currentFilters();
    if (mode === FilterEngineMode.CLASSIC) {
      this.filterCriteriaButtonTitle.set(criteria.fullLabel);
    }
    const component: Type<any> | null = this.filterComponentFactory.getComponent(criteria.element);
    if (component) {
      let currentFilters = null;
      if (criteria.options.multiSelect) {
        if (criteria.key === 'eventType' && criteria.options.criteriaKey === 'algo') {
          currentFilters = filters.filter((f) => f.criteriaKey === 'algo');
        } else if (criteria.key === 'eventStatus' && criteria.options.criteriaKey === 'isActive') {
          currentFilters = filters.filter((f) => f.criteriaKey === 'isActive');
        } else if (criteria.key === 'timezone' && criteria.options.criteriaKey === 'teamPlanningTimezoneDetailsName') {
          currentFilters = filters.filter((f) => f.criteriaKey === 'teamPlanningTimezoneDetailsName');
        } else {
          currentFilters = filters.filter((f) => f.criteriaKey === criteria.options.criteriaKey);
        }
      }

      const field = {
        component,
        inputs: {
          data: criteria.options,
          currentFiltersSize: currentFiltersNotHidden.length,
          maxFilters: this.maxFilters,
          currentFilters,
          onDispatchFilterValue: (componentOutput: Filter | Filter[]) => {
            if (componentOutput) {
              if (componentOutput instanceof Array) {
                componentOutput.forEach((f: Filter) => {
                  this.checkFilterDuplicate(f, criteria.options.multiSelect ?? false);
                });
              } else {
                this.checkFilterDuplicate(componentOutput, criteria.options.multiSelect ?? false);
              }
              this.onApplyFilters();
            }
          }
        }
      };
      if (mode === FilterEngineMode.FAVORITE) {
        this.favoriteFields.update((fields) => [...fields, field]);
      } else {
        this.classicFields.update(() => [field]);
      }
    }
  }

  checkFilterDuplicate(possibleDuplicateFilter: Filter, multiSelect = false): void {
    const workingFilters = this.workingFilters();
    const currentFiltersNotHidden = this.currentFiltersNotHidden();
    if (currentFiltersNotHidden.length < this.maxFilters) {
      if (!workingFilters.find((f) => f.criteriaKey === possibleDuplicateFilter.criteriaKey && f.value === possibleDuplicateFilter.value)) {
        this.workingFilters.update((value: Filter[]) => [...value, possibleDuplicateFilter]);
      } else {
        if (multiSelect) {
          const newFilters = workingFilters;
          if (possibleDuplicateFilter.criteriaKey !== 'includeSubEntities') {
            const index = newFilters.findIndex(
              (f: Filter) => f.criteriaKey === possibleDuplicateFilter.criteriaKey && f.value === possibleDuplicateFilter.value
            );
            newFilters.splice(index, 1);
          }
          if (possibleDuplicateFilter.criteriaKey === 'includeSubEntities' && !workingFilters.find((f) => f.criteriaKey === 'entityId')) {
            const indexHidden = newFilters.findIndex((f: Filter) => f.criteriaKey === 'includeSubEntities');
            if (indexHidden >= 0) {
              newFilters.splice(indexHidden, 1);
            }
          }
          this.workingFilters.set(newFilters);

          if (!workingFilters.length) {
            this.resetFilterCriteriaButtonTitle();
          }
        }
      }
    }
  }

  removeOneFilter(filterToRemove: Filter): void {
    const newFilters: Filter[] = this.workingFilters();
    const index: number = newFilters.findIndex((currentFilter: Filter) => currentFilter === filterToRemove);
    newFilters.splice(index, 1);

    if (filterToRemove.criteriaKey === 'entityId' && !newFilters.find((f) => f.criteriaKey === 'entityId')) {
      const indexHidden: number = newFilters.findIndex((currentFilter: Filter) => currentFilter.criteriaKey === 'includeSubEntities');
      if (indexHidden >= 0) {
        newFilters.splice(indexHidden, 1);
      }
    }
    this.workingFilters.set(newFilters);

    this.onApplyFilters();
    const workingFilters = this.workingFilters();
    if (!workingFilters.length) {
      this.resetFilterCriteriaButtonTitle();
    }
    this.refreshFieldsWorkingFilters();
  }

  onApplyFilters() {
    const workingFilters = this.workingFilters();
    this.applyFilters.emit(workingFilters);
  }

  onClearAllFilters(): void {
    this.workingFilters.set([]);
    this.onApplyFilters();
    this.resetFilterCriteriaButtonTitle();
    this.refreshFieldsWorkingFilters();
  }

  onResetFavoriteView(): void {
    const currentFavoriteView = this.currentFavoriteView();
    this.workingFilters.set([...(currentFavoriteView?.filters ?? [])]);
    this.onApplyFilters();
    this.resetFilterCriteriaButtonTitle();
    this.refreshFieldsWorkingFilters();
  }

  refreshFieldsWorkingFilters() {
    const workingFilters = this.workingFilters();
    const currentFiltersNotHidden = this.currentFiltersNotHidden();
    const doRefresh = (fields) => {
      // should update the value of current filters of each field not the reference to prevent the dom refresh
      fields.forEach((f) => {
        f.inputs.currentFiltersSize = currentFiltersNotHidden.length;
        f.inputs.currentFilters = workingFilters;
      });
      return fields;
    };
    this.favoriteFields.update(doRefresh);
    this.classicFields.update(doRefresh);
  }

  openManageFavoriteFilters(): void {
    const masterView = this.masterView();
    const filterCriteria = this.categories();
    const userPreferences = this.userPreferences();
    const user = this.user();
    this.dialog
      .open(ManageFavoriteFiltersPopupComponent, {
        data: {
          masterView,
          filterCriteria,
          userPreferences
        },
        disableClose: true,
        maxWidth: '1100px',
        minWidth: '600px'
      })
      .afterClosed()
      .subscribe((updatedFavoriteFilters) => {
        if (updatedFavoriteFilters) {
          const preferencesToUpdate: UserPreferences = {
            ...userPreferences,
            favoriteFilters: {
              ...userPreferences.favoriteFilters,
              [masterView]: { ...updatedFavoriteFilters }
            }
          };
          const userToUpdate: UserAccount = { ...cloneDeep(user), preferences: preferencesToUpdate };
          this.filterEngineSettingsService.saveUserPreferences(userToUpdate, preferencesToUpdate);
        }
      });
  }

  getFilterByUserPreferences(filterCriteria: FilterCriteriaConfiguration[], favoriteFilters: FavoriteFiltersByConcept): FilterConfiguration[] {
    const flatFilters = Object.values(favoriteFilters).flat();
    flatFilters.sort(SortUtil.sortByOrder);
    const flatFilterCriteria: FilterConfiguration[] = filterCriteria.map((fc) => fc.options).flat();
    return flatFilters.reduce((acc: FilterConfiguration[], f: { name: string; order: number }) => {
      const matchingFilterCriteria = flatFilterCriteria.find((fc) => fc.key === f.name);
      if (matchingFilterCriteria) {
        acc.push(matchingFilterCriteria);
      }
      return acc;
    }, []);
  }

  private initFavoriteFiltersEffect(): void {
    effect(
      () => {
        const pref: UserPreferences = this.userPreferences();
        const categories: FilterCriteriaConfiguration[] = this.categories();
        const masterView = this.masterView();
        const displayManageFavoriteFilters = this.displayManageFavoriteFilters();
        if (categories) {
          untracked(() => {
            this.favoriteFields.set([]);
            this.classicFields.set([]);
            const favoriteFilters: FilterConfiguration[] = this.getFavoriteFilters(pref, categories, masterView, displayManageFavoriteFilters);
            favoriteFilters.forEach((criteria) => this.addField(criteria));
          });
        }
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  private getFavoriteFilters(pref, categories, masterView, displayManageFavoriteFilters): FilterConfiguration[] {
    let favoriteFilters: FilterConfiguration[] = [];
    if (!!masterView && !!pref?.favoriteFilters && !!pref.favoriteFilters[masterView] && displayManageFavoriteFilters) {
      favoriteFilters = this.getFilterByUserPreferences(categories, pref.favoriteFilters[masterView]);
    } else {
      categories.some((category: FilterCriteriaConfiguration) => {
        if (category.options instanceof Array) {
          category.options.some((option: FilterConfiguration) => {
            if (option.defaultFavorite) {
              favoriteFilters.push(option);
            }
          });
        }
      });
    }
    return favoriteFilters.sort(SortUtil.sortByKey);
  }

  private initFavoriteViewEffect(): void {
    effect(
      () => {
        const fv: FavoriteView | null = this.currentFavoriteView();
        untracked(() => {
          if (fv) {
            this.resetFilterCriteriaButtonTitle();
            const fvFilters: Filter[] = fv?.filters ?? [];
            this.workingFilters.set([...fvFilters]);
            this.refreshFieldsWorkingFilters();
          }
        });
      },
      { allowSignalWrites: true }
    );
  }

  private initFilterEffect(): void {
    effect(
      () => {
        const currentFilters = this.currentFilters();
        const filters: Filter[] = currentFilters ?? [];
        if (filters) {
          this.workingFilters.set([...filters]);
        }
      },
      { allowSignalWrites: true }
    );
  }

  private initClearFiltersEffect(): void {
    effect(
      () => {
        const isClearFiltersTriggered: boolean = this.clearAppliedFilters();
        if (isClearFiltersTriggered) {
          this.resetFilterCriteriaButtonTitle();
        }
      },
      { allowSignalWrites: true }
    );
  }
}
