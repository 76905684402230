import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AssetVariable } from '@iot-platform/models/i4b';
import { DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, ValueUnitFormatPipe } from '@iot-platform/pipes';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    InfoDisplayPipe,
    NumberFormatPipe,
    DateFormatPipe,
    MatIconModule,
    MatButtonModule,
    ValueUnitFormatPipe,
    MatTooltipModule,
    NgClass
  ],
  selector: 'shared-iot4bos-asset-ui-variable-parameter',
  templateUrl: './variable-parameter.component.html',
  styleUrls: ['./variable-parameter.component.scss']
})
export class VariableParameterComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() parameter?: any;
  @Input() assetVariable?: AssetVariable;
  @Input() isFirstEmptyParameter = false;
  @Input() isUnitInvalid = true;
  @Input() isTouched = false;
  @Input() invalidUnitTooltip = '';
  @Input() placeholder = '';

  @Output() clearFormControl: EventEmitter<void> = new EventEmitter();
}
