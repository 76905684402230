import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NameValidators } from '@iot-platform/iot-platform-utils';
import { AssetVariable, FOLLOWED_NUMBERS, NORMALIZED_ASSET_VARIABLES, NormalizedAssetVariablesByCategory } from '@iot-platform/models/i4b';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { VariableUnitsAutocompleteComponent } from '../variable-units-autocomplete/variable-units-autocomplete.component';
import { AbstractVariableCreateFormService } from './abstract-variable-create-form.service';
import { sortNormalizedVariablesByCategory } from './variable-create-form.helper';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    VariableUnitsAutocompleteComponent
  ],
  selector: 'shared-iot4bos-asset-ui-variable-create-form',
  templateUrl: './variable-create-form.component.html',
  styleUrls: ['./variable-create-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariableCreateFormComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({});
  followedNumbers = [...FOLLOWED_NUMBERS];
  normalizedVariables: NormalizedAssetVariablesByCategory[] = [];
  filteredNormalizedVariablesByCategory: WritableSignal<NormalizedAssetVariablesByCategory[]> = signal([]);

  selectedNormalizedVariable: { name: string; unit: string; description: string };
  clearUnit = signal(false);

  constructor(
    private translateService: TranslateService,
    private variableCreateFormService: AbstractVariableCreateFormService,
    private dialogRef: MatDialogRef<VariableCreateFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      variable: Partial<AssetVariable>;
      canConfigure: boolean;
      followedVariableNumbers: number[];
      assetCategory?: string;
    }
  ) {}

  get title$(): Observable<string> {
    return this.data.variable
      ? this.translateService.get('ASSETS.VARIABLE_FORM.TITLE_EDIT', { value: this.data.variable.name })
      : this.translateService.get('ASSETS.VARIABLE_FORM.TITLE_CREATE');
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get unit(): AbstractControl {
    return this.form.get('unit');
  }

  get comment(): AbstractControl {
    return this.form.get('comment');
  }

  get followedNumber(): AbstractControl {
    return this.form.get('followedNumber');
  }

  ngOnInit() {
    this.initNormalizedVariables();
    this.initForm();
    this.initNameAutocomplete();
  }

  initNormalizedVariables(): void {
    this.normalizedVariables = sortNormalizedVariablesByCategory(NORMALIZED_ASSET_VARIABLES, this.data.assetCategory);
    this.filteredNormalizedVariablesByCategory.set([...this.normalizedVariables]);
  }

  initNameAutocomplete(): void {
    this.name?.valueChanges.subscribe((name) => {
      this.filteredNormalizedVariablesByCategory.set(
        this.normalizedVariables
          .map((normalizedVariableCategory: NormalizedAssetVariablesByCategory) => ({
            categoryName: normalizedVariableCategory.categoryName,
            variables: normalizedVariableCategory.variables.filter((v) => v.name.toLowerCase().includes(name?.toLowerCase()))
          }))
          .filter((normalizedVariableCategory: NormalizedAssetVariablesByCategory) => normalizedVariableCategory.variables.length > 0)
      );

      if (!this.isNormalizedVariable(name)) {
        this.selectedNormalizedVariable = undefined;
        this.unit?.reset();
        this.clearUnit.set(true);
      }
    });
  }

  isNormalizedVariable(variableName: string): boolean {
    return !!this.normalizedVariables.find((element) => element.variables.find((v) => v.name.toLowerCase() === variableName?.toLowerCase()));
  }

  getNormalizedVariable(): { name: string; unit: string; description: string } {
    let firstNormalizedVariable: { name: string; unit: string; description: string };
    this.normalizedVariables.find((element) => {
      const tempVar = element.variables.find((v) => v.name.toLowerCase() === this.name.value.toLowerCase());

      if (tempVar) {
        firstNormalizedVariable = tempVar;
      }
      return !!tempVar;
    });

    return firstNormalizedVariable;
  }

  onNormalizedVariableSelected(selectedVariable: { name: string; unit: string; description: string }): void {
    this.selectedNormalizedVariable = selectedVariable;
    this.unit.setValue(this.selectedNormalizedVariable.unit);
  }

  save(configure: boolean): void {
    this.dialogRef.close({
      variable: {
        name: this.name.value.trim(),
        comment: this.comment.value,
        unit: this.unit.value,
        followedNumber: this.followedNumber.value
      },
      configure
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.variable?.name?.trim() ?? '', {
        validators: [Validators.required, Validators.maxLength(40), Validators.pattern('\\S.*')],
        asyncValidators: [NameValidators.asyncUniqueNameValidator(this.variableCreateFormService, this.data.variable?.name ?? '')]
      }),
      unit: new UntypedFormControl(this.data.variable?.unit ?? '', [Validators.required]),
      comment: new UntypedFormControl(this.data.variable?.comment ?? '', [Validators.maxLength(60)]),
      followedNumber: new UntypedFormControl(this.data.variable?.followedNumber ?? null)
    });

    if (this.isNormalizedVariable(this.name.value)) {
      this.onNormalizedVariableSelected(this.getNormalizedVariable());
    }

    this.unit.setValue(this.data.variable?.unit ?? '');
  }
}
