import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { PlatformResponse } from '@iot-platform/models/common';
import { AbstractVariableCreateFormService } from '@iot-platform/shared/iot4bos/asset/ui';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { map, Observable } from 'rxjs';
import { NavigationApi } from '../../../containers/+state/navigation.api';

@Injectable({
  providedIn: 'root'
})
export class VariableCreateFormService extends AbstractVariableCreateFormService {
  private selectedAsset = toSignal(this.navigationApi.selectedAsset$);

  constructor(
    private readonly navigationApi: NavigationApi,
    private readonly assetVariablesService: AssetVariablesService
  ) {
    super();
  }

  isNameUnique(name: string): Observable<boolean> {
    return this.assetVariablesService
      .getAll({
        limit: 0,
        page: 0,
        filters: [{ criteriaKey: 'name', value: name }],
        urlParam: this.selectedAsset()?.id
      })
      .pipe(map((response: PlatformResponse) => (response.total > 0 ? false : true)));
  }
}
