<mat-card class="dialog-card" fxLayout="column">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.TITLE' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content" fxLayout="column">
    <form [formGroup]="manageFavoriteFiltersForm" class="p-20" fxLayout="column" fxLayoutGap="20px">
      <div class="manage-favorite-filters--filter-count" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>star_outlined</mat-icon>
        <span>{{
          'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.FILTER_COUNT'
            | translate
              : {
                  currentTotal: favoriteFiltersTotal,
                  max: favoriteFiltersMaximumPossible
                }
        }}</span>
        @if (favoriteFiltersControl.hasError('favoriteFiltersEmpty')) {
          <span class="manage-favorite-filters--no-filter-error" fxFlex>{{
            'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.ERROR_MESSAGES.NO_FILTER_SELECTED' | translate
          }}</span>
        }
      </div>
      <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px">
        @for (filtersByConcept of data.filterCriteria; track filtersByConcept) {
          <section fxLayout="column" fxLayoutGap="10px">
            <div class="manage-favorite-filters--filter-concept-label">{{ filtersByConcept.label | translate }}</div>
            <div class="manage-favorite-filters--filter-checkbox-container">
              @for (filter of filtersByConcept.options; track filter) {
                <mat-checkbox
                  (change)="onFilterSelectionChange($event, filtersByConcept.key)"
                  [checked]="isFavoriteFilter(filter, filtersByConcept.key)"
                  [disabled]="!isFavoriteFilter(filter, filtersByConcept.key) && favoriteFiltersTotal === favoriteFiltersMaximumPossible"
                  [value]="filter.key"
                  >{{ filter.label | translate }}
                </mat-checkbox>
              }
            </div>
          </section>
        }
      </div>
      <div class="manage-favorite-filters--drag-and-drop-container" fxLayout="column" fxLayoutGap="6px">
        <span>{{ 'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.DRAG_AND_DROP' | translate }}</span>
        <mat-chip-set (cdkDropListDropped)="drop($event)" cdkDropList cdkDropListOrientation="horizontal" class="chip-container" fxFlex>
          @for (favoriteFilter of favoriteFiltersForOrdering; track favoriteFilter) {
            <mat-chip-option [selectable]="false" cdkDrag class="chip-filter" fxLayoutAlign="start center" fxLayoutGap="4px">
              <mat-icon>drag_indicator</mat-icon>
              <span>{{ favoriteFilter.name }} </span>
            </mat-chip-option>
          }
        </mat-chip-set>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="resetToAppDefault()" class="button-regular" color="accent" mat-button>
      {{ 'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.RESET_TO_APP_DEFAULT' | translate }}
    </button>
    <button
      (click)="save()"
      [disabled]="!manageFavoriteFiltersForm.valid || !manageFavoriteFiltersForm.dirty"
      class="button-regular"
      color="accent"
      mat-raised-button
    >
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
