import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { CustomCellParams } from '../custom-cell.params';

//
@Component({
  selector: 'grid-engine-topic-self-subscription-cell',
  templateUrl: './topic-self-subscription-cell.component.html',
  styleUrls: ['./topic-self-subscription-cell.component.scss']
})
export class TopicSelfSubscriptionCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;
  subscribed: boolean;

  agInit(params: CustomCellParams): void {
    this.params = params;

    this.subscribed = !!this.params.data?.subscribedAt;
  }

  refresh(): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent) {
    if (event) {
      event.stopPropagation();
      this.params.dispatchEvent({
        type: this.params.eventConfiguration.type,
        options: { ...this.params.eventConfiguration.options, subscribed: this.subscribed },
        rawData: this.params.data
      });
    }
  }
}
