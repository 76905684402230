import { Component, effect, OnInit, signal } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SortUtil } from '@iot-platform/iot-platform-utils';

import { Filter, FilterSelectOptions } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

import { FilterComponent } from '../../models/filter.component';
import { AbstractFilterEngineFieldComponent } from '../abstract-filter-engine-field.component';

@Component({
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule],
  selector: 'iot-platform-ui-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent extends AbstractFilterEngineFieldComponent<FilterSelectOptions> implements OnInit, FilterComponent {
  form: UntypedFormGroup = new UntypedFormGroup({
    select: new UntypedFormControl('')
  });
  selectedFilters = signal([]);

  get select(): AbstractControl {
    return this.form.get('select');
  }

  ngOnInit() {
    this.initData();
    this.initSelectedFiltersEffect();
  }

  onSelectionChange(): void {
    const data = this.data();
    const selectedFilters = this.selectedFilters();
    if (!data.multiSelect) {
      this.dispatchFilter(this.select.value);
      this.form.reset();
    } else {
      let diff: { key: string; value: string };
      if (selectedFilters.length > this.select.value.length) {
        diff = this.getDifference(selectedFilters, this.select.value);
        this.currentFiltersSize.update((v) => v--);
      } else {
        diff = this.getDifference(this.select.value, selectedFilters);
        this.currentFiltersSize.update((v) => v++);
      }
      this.dispatchFilter(diff);
      this.selectedFilters.set([...this.select.value]);
    }
  }

  getDifference(
    array1: { key: string; value: string }[],
    array2: { key: string; value: string }[]
  ): {
    key: string;
    value: string;
  } {
    return array1.filter((obj1) => !array2.some((obj2) => obj1.key === obj2.key))[0];
  }

  dispatchFilter(value: { key: string; value: string }) {
    const data = this.data();
    const filter: Filter = {};
    filter.value = value.key;
    filter.label = value.value;
    filter.criteriaKey = data.criteriaKey;
    filter.criteriaLabel = data.criteriaLabel;
    this.dispatchFilterEvent(filter);
  }

  compareFn(filter1: any, filter2: any): boolean {
    return filter1?.key === filter2?.key;
  }

  private initSelectedFiltersEffect() {
    effect(
      () => {
        const data = this.data();
        const filters = this.currentFilters();
        if (data?.multiSelect) {
          const selectedFilters = [];
          if (filters.filter((f) => f.criteriaKey === data.criteriaKey).length > 0) {
            filters.forEach((filter: Filter) => {
              selectedFilters.push({ key: filter.value, value: filter.label });
            });
          }
          this.select.setValue(selectedFilters);
          this.selectedFilters.set(selectedFilters);
        }
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  private initData() {
    const data = this.data();
    const hasOrderKey: boolean = data.list.some((e: { key: string; value: string; order?: number }) => Object.prototype.hasOwnProperty.call(e, 'order'));
    data.list.sort(SortUtil.sortByProperty(hasOrderKey ? 'order' : 'key'));
  }
}
