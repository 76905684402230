import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EmailTemplate } from '@iot-platform/models/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateCellService {
  cachedTemplate$ = new BehaviorSubject<EmailTemplate[]>([]);
  templateIds = new Set<string>();

  constructor(
    @Inject('environment') private readonly environment,
    private readonly httpClient: HttpClient
  ) {}

  getEmailTemplateById(templateId: string): Observable<EmailTemplate> {
    if (this.templateIds.has(templateId)) {
      return this.cachedTemplate$.pipe(map((templates: EmailTemplate[]) => templates.find((t) => t.id === templateId)));
    } else {
      this.templateIds.add(templateId);
      return this.httpClient.get<EmailTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.notificationTemplates}/${templateId}`).pipe(
        tap((t) => {
          this.cachedTemplate$.next([...this.cachedTemplate$.getValue(), t]);
        })
      );
    }
  }
}
