import { Component, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import * as momentTimezones from 'moment-timezone';
import { AsyncAutocompleteComponent } from '../async-autocomplete/async-autocomplete.component';

type Timezone = { key: string; value: string };

@Component({
  standalone: true,
  imports: [AsyncAutocompleteComponent, TranslateModule, FlexLayoutModule],
  selector: 'iot-platform-ui-timezone-autocomplete',
  templateUrl: './timezone-autocomplete.component.html',
  styleUrls: ['./timezone-autocomplete.component.scss']
})
export class TimezoneAutocompleteComponent implements OnInit {
  placeholder = input<string>('IOT_DICTIONARY.TIMEZONE');
  initialItem = input<string | null>(null);
  clearOnSelect = input<boolean>(true);
  required = input<boolean>(false);
  displayOffset = input<boolean>(false);
  errorMessage = input<string>();
  //
  selectionChange = output<string>();
  reset = output<void>();

  timezones: WritableSignal<Timezone[]> = signal([]);
  selectedTimezone: WritableSignal<Timezone | undefined> = signal(undefined);
  filteredTimezones: WritableSignal<Timezone[]> = signal([]);

  ngOnInit(): void {
    this.timezones.set(
      momentTimezones.tz.names().map((timezone: string) => ({
        key: this.displayOffset() ? `${timezone} ${momentTimezones.tz(timezone).format('Z')}` : timezone,
        value: timezone
      }))
    );

    this.filteredTimezones.set([...this.timezones()]);
    this.selectedTimezone.set([...this.timezones()].find(({ value }) => value.toLowerCase().trim() === this.initialItem()?.toLowerCase().trim()));
  }

  onSearch(timezone: string): void {
    this.filteredTimezones.set(this.timezones().filter(({ key }) => key.toLowerCase().includes(timezone.toLowerCase())));
    this.reset.emit();
  }

  onReset(): void {
    this.filteredTimezones.set([...this.timezones()]);
    this.selectedTimezone.set(undefined);
    this.reset.emit();
  }

  onSelect(selected: Timezone): void {
    if (selected) {
      this.selectionChange.emit(selected.value);
    }
  }
}
