import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Entity } from '@iot-platform/models/common';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EntityCellService {
  entities$: BehaviorSubject<Entity[]> = new BehaviorSubject<Entity[]>([]);

  constructor(
    @Inject('environment') private readonly environment,
    private readonly http: HttpClient
  ) {}

  public getEntityById(entityId): Observable<Entity> {
    return this.entities$.pipe(
      switchMap((entities: Entity[]) => {
        const found = entities.find((e) => e.id === entityId);
        if (found) {
          return of(found);
        } else {
          return this.http.get<Entity>(`${this.environment.api.url}/entities/${entityId}`).pipe(
            tap((e) => {
              entities.push(e);
              this.entities$.next(entities);
            })
          );
        }
      })
    );
  }
}
