import { LowerCasePipe, UpperCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ArrayUtils } from '@iot-platform/iot-platform-utils';
import { TagCategory, TagLabel } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { TagEditorComponent } from '../tag-editor/tag-editor.component';
import { SelectedTag, TagsByEntity } from './manage-tags-form.model';
import { ManageTagsFormService } from './manage-tags-form.service';

// TODO: Component should be refactored to be less complex and easy to maintain

interface ManageTagsFormData {
  concepts: string[];
  openOnConcept?: string;
  selectedTags: TagCategory[];
  objectName: string;
  currentEntityId: string;
  multiSelection: boolean;
  editable: boolean;
  withChildren: boolean;
  withParents: boolean;
  joinable: boolean;
  minimumSelectionCount?: number;
}

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    UpperCasePipe,
    MatChipsModule,
    TagEditorComponent,
    LowerCasePipe,
    MatButtonModule
  ],
  selector: 'iot-platform-ui-manage-tags-form',
  templateUrl: './manage-tags-form.component.html',
  styleUrls: ['./manage-tags-form.component.scss']
})
export class ManageTagsFormComponent implements OnInit {
  private readonly manageTagsFormService: ManageTagsFormService = inject(ManageTagsFormService);
  public readonly dialogRef: MatDialogRef<ManageTagsFormComponent> = inject(MatDialogRef);
  public readonly data: ManageTagsFormData = inject(MAT_DIALOG_DATA);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  form: UntypedFormGroup = new UntypedFormGroup({
    concept: new UntypedFormControl(this.data.concepts.length === 1 ? this.data.concepts[0] : this.data.openOnConcept ? this.data.openOnConcept : '', [
      Validators.required
    ])
  });

  selectedTags: WritableSignal<SelectedTag[]> = signal([]);
  tagsLoading: WritableSignal<boolean> = signal(true);
  currentTags: WritableSignal<{ [concept: string]: TagsByEntity[] }> = signal({});
  tags: Signal<{ [concept: string]: TagsByEntity[] } | undefined> = toSignal(
    this.manageTagsFormService
      .getTagCategoriesByConceptSortedByEntity(this.data.concepts, this.data.currentEntityId, this.data.withChildren, this.data.withParents, this.data.joinable)
      .pipe(
        finalize(() => this.tagsLoading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
  );

  canClose: WritableSignal<boolean> = signal(false);
  canRemove: Signal<boolean> = signal(this.selectedTags.length > (this.data?.minimumSelectionCount ?? 0));
  disableSaveButton: Signal<boolean> = computed(() => {
    const currentSelectedTags = this.selectedTags();
    const currentSelectedTagsIds = currentSelectedTags.map((tag: TagCategory) => tag.id);
    const initialSelectedTags = this.data?.selectedTags ?? [];
    const initialTagsIds = initialSelectedTags.flatMap((tag: TagCategory) => tag.labels?.map((label) => label.id));
    if (initialSelectedTags.length === 0 && currentSelectedTags.length === 0) {
      return true;
    }
    return ArrayUtils.isEqual(initialTagsIds, currentSelectedTagsIds);
  });

  initCurrentTagsEffect = effect(
    () => {
      const tags = this.tags();
      if (tags) {
        this.currentTags.set(tags);
      }
    },
    { allowSignalWrites: true }
  );

  get concept(): AbstractControl {
    return this.form.get('concept') as AbstractControl;
  }

  ngOnInit(): void {
    this.initSelectedTags();
  }

  addSelectedTag(tagCategory: TagCategory, tag: TagLabel): void {
    if (tag.selected) return;

    this.setSelectedLabelStatus(tagCategory.concept as string, tagCategory.entityId as string, tagCategory.id as string, tag.id as string, true);

    const newSelectedTag: SelectedTag = {
      id: tag.id as string,
      name: tag.name,
      concept: tagCategory.concept as string,
      entityId: tagCategory.entityId as string,
      color: tagCategory.color as string,
      categoryId: tagCategory.id as string,
      categoryName: tagCategory.name as string,
      selected: true,
      label: tag,
      category: tagCategory
    };

    if (this.data.multiSelection) {
      this.selectedTags.update((tags: SelectedTag[]) => [...tags, newSelectedTag]);
    } else {
      this.selectedTags.update((tags: SelectedTag[]) => {
        const updatedTags = tags.filter((elem: SelectedTag) => elem.categoryId !== newSelectedTag.categoryId);
        return [...updatedTags, newSelectedTag];
      });

      const previousTag = this.selectedTags().find((elem: SelectedTag) => elem.categoryId === newSelectedTag.categoryId);
      if (previousTag) {
        this.setSelectedLabelStatus(previousTag.concept, previousTag.entityId, previousTag.categoryId, previousTag.id, false);
      }
    }
  }

  removeSelectedTag(selectedTag: SelectedTag): void {
    selectedTag.selected = false;
    this.setSelectedLabelStatus(selectedTag.concept, selectedTag.entityId, selectedTag.categoryId, selectedTag.id, false);
    this.selectedTags.update((tags: SelectedTag[]) => tags.filter((tag) => tag.id !== selectedTag.id));
  }

  getSelectedTagsByEntityTotal(tagCategories: TagCategory[]): number {
    return tagCategories.reduce((total, category) => total + (category.labels?.filter((label) => label.selected).length ?? 0), 0);
  }

  save(): void {
    const toBeSaved: TagCategory[] = this.selectedTags().reduce((acc: TagCategory[], value: SelectedTag) => {
      const foundIndex = acc.findIndex((v: TagCategory) => v.entityId === value.entityId);

      if (foundIndex === -1) {
        acc.push({
          name: value.categoryName,
          id: value.categoryId,
          concept: value.concept,
          color: value.color,
          labels: [{ id: value.id, name: value.name }]
        });
      } else {
        acc[foundIndex] = {
          ...acc[foundIndex],
          labels: [...(acc[foundIndex]?.labels as TagLabel[]), { id: value.id, name: value.name }]
        };
      }

      return acc;
    }, []);

    this.dialogRef.close(toBeSaved);
  }

  private setSelectedLabelStatus(concept: string, entityId: string, categoryId: string, labelId: string, selected: boolean): void {
    this.currentTags.update((tags) => {
      const updatedTags = { ...tags };
      const label = updatedTags?.[concept]
        ?.find((e) => e.entityId === entityId)
        ?.tagCategories.find((c) => c.id === categoryId)
        ?.labels?.find((l) => l.id === labelId);

      if (label) {
        label.selected = selected;
      }

      return updatedTags;
    });
  }

  private initSelectedTags(): void {
    if (this.data.selectedTags) {
      const selectedTags = [...this.data.selectedTags];
      const tags = selectedTags.reduce((acc: SelectedTag[], value: TagCategory) => {
        const selectedTag: SelectedTag[] = value.labels?.map((label: TagLabel): SelectedTag => {
          const elem: SelectedTag = {
            id: label.id as string,
            name: label.name,
            categoryId: value.id as string,
            categoryName: value.name as string,
            color: value.color as string,
            concept: value.concept as string,
            entityId: value.entityId as string,
            selected: true,
            category: value,
            label: { ...label, selected: true }
          };

          this.setSelectedLabelStatus(elem.concept, elem.entityId, elem.categoryId, elem.id, true);
          return elem;
        }) as SelectedTag[];
        acc.push(...selectedTag);
        return acc;
      }, []);

      this.selectedTags.set(tags);
    }
  }
}
