import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, inject, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Country, CountryFilterType } from '@iot-platform/models/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncAutocompleteComponent } from '../async-autocomplete/async-autocomplete.component';

@Component({
  standalone: true,
  imports: [AsyncAutocompleteComponent, TranslateModule, FlexLayoutModule],
  selector: 'iot-platform-ui-country-autocomplete',
  templateUrl: './country-autocomplete.component.html',
  styleUrls: ['./country-autocomplete.component.scss']
})
export class CountryAutocompleteComponent implements OnInit {
  placeholder = input<string>('IOT_DICTIONARY.COUNTRY');
  filterType = input<CountryFilterType>(CountryFilterType.ALL);
  initialItem = input<string | null>(null);
  clearOnSelect = input<boolean>(true);
  required = input<boolean>(false);
  errorMessage = input<string>();
  //
  selectionChange = output<Country>();
  reset = output<void>();

  countries: WritableSignal<Country[]> = signal([]);
  filteredItems: WritableSignal<Country[]> = signal([]);
  selectedCountry: WritableSignal<Country | undefined> = signal(undefined);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly destroy: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.http
      .get<Country[]>('/assets/data/countries.json')
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((countries: Country[]) => {
        this.countries.set(countries);
        this.filteredItems.set(countries);

        if (this.initialItem() && this.filterType() !== CountryFilterType.ALL) {
          this.selectedCountry.set(this.countries().find((c: Country) => c[this.filterType()] === this.initialItem()));
        }
      });
  }

  onSearch(searchTerm: string): void {
    const filtered: Country[] = this.countries().filter(({ name, countryCode, countryCodeAlpha3, phone }) => {
      const searchObject = {
        name,
        countryCode: '',
        countryCodeAlpha3: '',
        phone: ''
      };
      switch (this.filterType()) {
        case CountryFilterType.CCA2:
          searchObject.countryCode = countryCode;
          break;
        case CountryFilterType.CCA3:
          searchObject.countryCodeAlpha3 = countryCodeAlpha3;
          break;
        case CountryFilterType.PHONE:
          searchObject.phone = phone;
          break;
        case CountryFilterType.NAME:
          break;
        default:
          searchObject.name = name;
          searchObject.countryCode = countryCode;
          searchObject.countryCodeAlpha3 = countryCodeAlpha3;
          searchObject.phone = phone;
          break;
      }
      return JSON.stringify(searchObject).toLowerCase().includes(searchTerm.toLowerCase());
    });
    this.filteredItems.set([...filtered]);
  }

  onReset(): void {
    this.filteredItems.set([...this.countries()]);
    this.selectedCountry.set(undefined);
    this.reset.emit();
  }

  onSelect(selected: Country): void {
    if (selected) {
      this.selectionChange.emit(selected);
    }
  }

  displayWrapper(country: Country): string {
    if (country) {
      const countryName: string = this.getCountryName(country);
      if (this.filterType() === CountryFilterType.PHONE) {
        return country.phone ? `${countryName} (${country.phone})` : countryName;
      } else if (this.filterType() !== CountryFilterType.NAME && this.filterType() !== CountryFilterType.ALL) {
        return `${countryName} (${country[this.filterType()]})`;
      } else {
        return countryName;
      }
    } else {
      return '';
    }
  }

  private getCountryName(country: Country): string {
    return this.translateService.instant('SITES.CARD.COUNTRIES.' + country.countryCodeAlpha3);
  }
}
