@if (displayWrapper) {
  <div [formGroup]="searchForm" class="iot-platform-ui-async-autocomplete"
       data-cy="iot-platform-ui-async-autocomplete">
    <mat-form-field class="full-width form-field" color="accent">
      @if (displaySearchIcon()) {
        <mat-icon class="search-icon" matPrefix>search</mat-icon>
      }
      <mat-label>{{ placeholder() | translate }}</mat-label>
      @if (autocomplete()) {
        <input
          #inputAutoComplete
          #trigger="matAutocompleteTrigger"
          [matAutocomplete]="auto"
          [required]="required()"
          data-cy="iot-platform-ui-async-autocomplete-input-field"
          formControlName="searchKey"
          matInput
          type="text"
        />
      } @else {
        <input [required]="required()" data-cy="iot-platform-ui-async-autocomplete-input-field"
               formControlName="searchKey" matInput type="text" />
      }
      @if (!_showSpinner() && control?.value && !control?.disabled) {
        <button (click)="resetControl($event)" aria-label="Clear" class="close-btn" mat-icon-button matIconSuffix
                type="button">
          <mat-icon>close</mat-icon>
        </button>
      }
      @if (hintMessage()) {
        <mat-hint>{{ hintMessage() }}</mat-hint>
      }
      @if (_showSpinner()) {
        <mat-spinner class="form-field_spinner" color="accent" diameter="16" mode="indeterminate"></mat-spinner>
      }
      @if (required() && errorMessage() && control.invalid) {
        <mat-error>{{ errorMessage() | translate }}</mat-error>
      }
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)"
                      [displayWith]="displayWrapper">
      @if (!_showSpinner()) {
        @for (item of dataToDisplay(); track item) {
          <mat-option
            [matTooltip]="tooltip() ? displayWrapper(item) : ''"
            [value]="item"
            data-cy="iot-platform-ui-async-autocomplete-option"
            matTooltipClass="regular-tooltip"
          >
            <span>{{ displayWrapper(item) }}</span>
          </mat-option>
        }
      }
    </mat-autocomplete>
  </div>
} @else {
  <div [formGroup]="searchForm" class="iot-platform-ui-async-autocomplete"
       data-cy="iot-platform-ui-async-autocomplete">
    <mat-form-field class="full-width form-field" color="accent">
      @if (displaySearchIcon()) {
        <mat-icon class="search-icon" matPrefix>search</mat-icon>
      }
      <mat-label>{{ placeholder() | translate }}</mat-label>
      @if (autocomplete()) {
        <input
          #inputAutoComplete
          #trigger="matAutocompleteTrigger"
          [matAutocomplete]="auto"
          [required]="required()"
          data-cy="iot-platform-ui-async-autocomplete-input-field"
          formControlName="searchKey"
          matInput
          type="text"
        />
      } @else {
        <input [required]="required()" data-cy="iot-platform-ui-async-autocomplete-input-field"
               formControlName="searchKey" matInput type="text" />
      }
      @if (!_showSpinner() && control?.value && !control?.disabled) {
        <button (click)="resetControl($event)" aria-label="Clear" class="close-btn" mat-icon-button matIconSuffix
                type="button">
          <mat-icon>close</mat-icon>
        </button>
      }
      @if (hintMessage()) {
        <mat-hint>{{ hintMessage() }}</mat-hint>
      }
      @if (_showSpinner()) {
        <mat-spinner class="form-field_spinner" color="accent" diameter="16" mode="indeterminate"></mat-spinner>
      }
      @if (required() && errorMessage() && control.invalid) {
        <mat-error>{{ errorMessage() | translate }}</mat-error>
      }
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)"
                      [displayWith]="defaultDisplayWrapper">
      @if (!_showSpinner()) {
        @for (item of dataToDisplay(); track item) {
          <mat-option
            [matTooltip]="tooltip() ? defaultDisplayWrapper(item) : ''"
            [value]="item"
            data-cy="iot-platform-ui-async-autocomplete-option"
            matTooltipClass="regular-tooltip"
          >
            <span>{{ defaultDisplayWrapper(item) }}</span>
          </mat-option>
        }
      }
    </mat-autocomplete>
  </div>
}
