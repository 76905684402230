<mat-card class="dialog-card" fxLayout="column">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'MANAGE_TAGS_FORM.TITLE' | translate: { value: data.objectName } }}</h1>

      <button (click)="dialogRef.close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" fxLayout="column">
    <div class="p-20">
      <section [formGroup]="form" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px">
        <mat-form-field class="concept-select" color="accent">
          <mat-label>{{ 'MANAGE_TAGS_FORM.CONCEPT_PLACEHOLDER' | translate }}</mat-label>
          <mat-select formControlName="concept">
            <mat-select-trigger fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon
                [color]="'#394C5A'"
                [ngClass]="['concept-icon', 'concept-icon' + concept.value.toLowerCase()]"
                [svgIcon]="concept.value.toLowerCase()"
              ></mat-icon>
              <span class="concept-name">{{ concept.value }}</span>
            </mat-select-trigger>

            @for (concept of data.concepts; track concept) {
              <mat-option [value]="concept">
                <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-icon [color]="'#394C5A'" [svgIcon]="concept"></mat-icon>
                  <span class="concept-name">{{ concept | uppercase }}</span>
                </span>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        @if (tagsLoading()) {
          <mat-progress-spinner [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
        }
      </section>

      <section class="tag-lists">
        <mat-accordion [multi]="true" class="tag-list-accordion">
          @for (tagsByEntity of currentTags()[concept.value.toUpperCase()]; track tagsByEntity) {
            <mat-expansion-panel
              [disabled]="
                (!tagsByEntity.tagCategories?.length && !data.editable) ||
                (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
              "
              [hideToggle]="
                (!tagsByEntity.tagCategories?.length && !data.editable) ||
                (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
              "
            >
              <mat-expansion-panel-header class="entity-header">
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                  <span
                    [ngClass]="{
                      'no-tag':
                        (!tagsByEntity.tagCategories?.length && !data.editable) ||
                        (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
                    }"
                    class="entity-name-selected"
                  >
                    <span class="entity-name">{{ tagsByEntity.entityName }}</span>
                    @if (getSelectedTagsByEntityTotal(tagsByEntity.tagCategories) !== 0) {
                      <span class="entity-tag-selected">
                        {{ getSelectedTagsByEntityTotal(tagsByEntity.tagCategories) }}{{ 'MANAGE_TAGS_FORM.TAG_SELECTED' | translate }}
                      </span>
                    }
                  </span>
                  <div class="panel-header-line" fxFlex></div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              @for (tagCategory of tagsByEntity.tagCategories; track tagCategory) {
                <div class="tag-by-entity-container" fxLayout="row" fxLayoutAlign="start start">
                  @if ((tagCategory?.entityId === data.currentEntityId && !data.editable) || tagCategory?.entityId !== data.currentEntityId) {
                    <div class="tag-by-entity" fxLayout="row">
                      <div class="tag-category-color-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                        <div [ngStyle]="{ backgroundColor: tagCategory?.color }" class="tag-category-color"></div>
                        <span class="tag-category-name capitalize-first-letter">{{ tagCategory?.name }}</span>
                      </div>
                      @if ((tagCategory?.entityId === data.currentEntityId && !data.editable) || tagCategory?.entityId !== data.currentEntityId) {
                        <mat-chip-set [disabled]="data.editable" class="tag-list-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                          @for (tag of tagCategory?.labels; track tag) {
                            <mat-chip-option
                              (click)="!data.editable && !tag.selected ? addSelectedTag(tagCategory, tag) : ''"
                              [class.disabled]="tag.selected"
                              [class.not-disabled]="!tag.selected"
                              [ngStyle]="{ backgroundColor: tagCategory?.color }"
                              [selectable]="false"
                              disableRipple="true"
                            >
                              {{ tag.name }}
                            </mat-chip-option>
                          }
                        </mat-chip-set>
                      }
                    </div>
                  }
                </div>
              }
              @if (tagsByEntity?.entityId === data.currentEntityId && data.editable) {
                <iot-platform-ui-tag-editor
                  (changeValue)="canClose.set($event)"
                  [concept]="concept.value"
                  [organizationId]="data.currentEntityId"
                ></iot-platform-ui-tag-editor>
              }
            </mat-expansion-panel>
          }
        </mat-accordion>
      </section>
    </div>

    @if (!data.editable) {
      <section class="selected-tags" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div class="fw-600">{{ 'MANAGE_TAGS_FORM.SELECTED' | translate }}</div>
        @if (selectedTags().length) {
          <mat-chip-set fxFlex>
            @for (selectedTag of selectedTags(); track selectedTag) {
              <mat-chip-option
                (click)="canRemove() && removeSelectedTag(selectedTag)"
                [class.removed]="!selectedTag.selected"
                [class.selected]="selectedTag.selected"
                [ngStyle]="{ backgroundColor: selectedTag.color, cursor: canRemove() ? 'pointer' : 'default' }"
                [selectable]="false"
                disableRipple="true"
                fxLayoutAlign="start center"
                fxLayoutGap="4px"
              >
                <mat-icon [svgIcon]="selectedTag.concept | lowercase"></mat-icon>
                <span>{{ selectedTag.categoryName }} : {{ selectedTag.name }}</span>
                @if (canRemove()) {
                  <mat-icon>close</mat-icon>
                }
              </mat-chip-option>
            }
          </mat-chip-set>
        }
      </section>
    }
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    @if (data.editable) {
      <button (click)="dialogRef.close()" [disabled]="!canClose" class="button-regular" color="accent" mat-raised-button>
        {{ 'MANAGE_TAGS_FORM.DONE' | translate }}
      </button>
    } @else {
      <button (click)="dialogRef.close()" class="button-regular" mat-button>{{ 'MANAGE_TAGS_FORM.CANCEL' | translate }}</button>
      <button (click)="save()" [disabled]="disableSaveButton()" class="button-regular" color="accent" mat-raised-button>
        {{ 'MANAGE_TAGS_FORM.CONFIRM' | translate }}
      </button>
    }
  </mat-card-actions>
</mat-card>
