import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { EnterpriseCoreModule } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { EventSeverityDisplayModule, FilterEngineModule, SubscriptionButtonModule } from '@iot-platform/iot-platform-ui';
import { CronDisplayPipe, DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, UserNamePipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { noop } from 'rxjs';
import { GridManagerComponent } from './components/containers/grid-manager/grid-manager.component';
import { GridPageComponent } from './components/containers/grid-page/grid-page.component';
import { AbstractAssetVariableConfigureReadPopupService } from './components/grid-manager-popups/asset-variable-configure-read-popup/abstract-asset-variable-configure-read-popup.service';
import { GridManagerAdminPopupComponent } from './components/grid-manager-popups/grid-manager-admin-popup/grid-manager-admin-popup.component';
import { GridManagerUserPopupComponent } from './components/grid-manager-popups/grid-manager-user-popup/grid-manager-user-popup.component';
import { ActiveEventsCellComponent } from './components/grid/cells/active-events-cell/active-events-cell.component';
import { AddressAccuracyCellComponent } from './components/grid/cells/address-accuracy-cell/address-accuracy-cell.component';
import { ArrayOfObjectsCellComponent } from './components/grid/cells/array-of-objects-cell/array-of-objects-cell.component';
import { AssetTemplateCellComponent } from './components/grid/cells/asset-template-cell/asset-template-cell.component';
import { AssetVariableCellComponent } from './components/grid/cells/asset-variable-cell/asset-variable-cell.component';
import { AssetVariableThresholdCellComponent } from './components/grid/cells/asset-variable-threshold-cell/asset-variable-threshold-cell.component';
import { BasicCellComponent } from './components/grid/cells/basic-cell/basic-cell.component';
import { BasicLinkCellComponent } from './components/grid/cells/basic-link-cell/basic-link-cell.component';
import { BooleanCellComponent } from './components/grid/cells/boolean-cell/boolean-cell.component';
import { CallToActionCellComponent } from './components/grid/cells/call-to-action-cell/call-to-action-cell.component';
import { CallToActionMenuItemComponent } from './components/grid/cells/call-to-action-cell/call-to-action-menu-item/call-to-action-menu-item.component';
import { CallToActionHeaderComponent } from './components/grid/cells/call-to-action-header/call-to-action-header.component';
import { ColorCellComponent } from './components/grid/cells/color-cell/color-cell.component';
import { CommentCellComponent } from './components/grid/cells/comment-cell/comment-cell.component';
import { CountCellComponent } from './components/grid/cells/count-cell/count-cell.component';
import { CronReadableCellComponent } from './components/grid/cells/cron-readable-cell/cron-readable-cell.component';
import { CyclicalCallFaultCellComponent } from './components/grid/cells/cyclical-call-fault-cell/cyclical-call-fault-cell.component';
import { DateCellComponent } from './components/grid/cells/date-cell/date-cell.component';
import { DiagnosticVariableCellComponent } from './components/grid/cells/diagnostic-variable-cell/diagnostic-variable-cell.component';
import { DynamicIconCellComponent } from './components/grid/cells/dynamic-icon-cell/dynamic-icon-cell.component';
import { EmailTemplateCellComponent } from './components/grid/cells/email-template-cell/email-template-cell.component';
import { EntityCellComponent } from './components/grid/cells/entity-cell/entity-cell.component';
import { EventDurationCellComponent } from './components/grid/cells/event-duration-cell/event-duration-cell.component';
import { FollowedNumberCellComponent } from './components/grid/cells/followed-number-cell/followed-number-cell.component';
import { IconCellComponent } from './components/grid/cells/icon-cell/icon-cell.component';
import { LastCommandStatusCellComponent } from './components/grid/cells/last-command-status-cell/last-command-status-cell.component';
import { LinkedCellComponent } from './components/grid/cells/linked-cell/linked-cell.component';
import { NumberCellComponent } from './components/grid/cells/number-cell/number-cell.component';
import { OutgoingConnectorCellComponent } from './components/grid/cells/outgoing-connector-cell/outgoing-connector-cell.component';
import { RichVariableValueCellComponent } from './components/grid/cells/rich-variable-value-cell/rich-variable-value-cell.component';
import { SeverityCellComponent } from './components/grid/cells/severity-cell/severity-cell.component';
import { TagCellComponent } from './components/grid/cells/tag-cell/tag-cell.component';
import { TopicSelfSubscriptionCellComponent } from './components/grid/cells/topic-self-subscription-cell/topic-self-subscription-cell.component';
import { TranslatedCellComponent } from './components/grid/cells/translated-cell/translated-cell.component';
import { UserCellComponent } from './components/grid/cells/user-cell/user-cell.component';
import { VariableDispatchCellComponent } from './components/grid/cells/variable-dispatch-cell/variable-dispatch-cell.component';
import { RichVariableValueCellFilterComponent } from './components/grid/filters/rich-variable-value-cell-filter/rich-variable-value-cell-filter.component';
import { GridEngineComponent } from './components/grid/grid-engine/grid-engine.component';
import { GridExportDialogComponent } from './components/grid/grid-export/grid-export-dialog/grid-export-dialog.component';
import { GridExportComponent } from './components/grid/grid-export/grid-export.component';
import { BasicHeaderCellComponent } from './components/grid/header-cells/basic-header-cell/basic-header-cell.component';
import { EmptyHeaderCellComponent } from './components/grid/header-cells/empty-header-cell/empty-header-cell.component';
import { GroupHeaderCellComponent } from './components/grid/header-cells/group-header-cell/group-header-cell.component';
import { HeaderShellComponent } from './components/grid/header-cells/header-shell/header-shell.component';
import { IconAndTextHeaderCellComponent } from './components/grid/header-cells/icon-and-text-header-cell/icon-and-text-header-cell.component';
import { IconHeaderCellComponent } from './components/grid/header-cells/icon-header-cell/icon-header-cell.component';
import { PaginatorComponent } from './components/grid/pagination/paginator/paginator.component';
import { GridsEffects } from './components/state/effects/grids.effects';
import * as fromGrids from './components/state/reducers';
import { GridEngineSettingsService } from './services/grid-engine-settings.service';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  SetFilterModule,
  MenuModule,
  ColumnsToolPanelModule,
  EnterpriseCoreModule,
  RowGroupingModule,
  FiltersToolPanelModule,
  ClipboardModule,
  RangeSelectionModule
]);

const HEADER_COMPONENTS = [
  BasicHeaderCellComponent,
  IconAndTextHeaderCellComponent,
  IconHeaderCellComponent,
  GroupHeaderCellComponent,
  EmptyHeaderCellComponent
];
const FILTER_COMPONENTS = [RichVariableValueCellFilterComponent];

const CELL_COMPONENTS = [
  BasicCellComponent,
  CyclicalCallFaultCellComponent,
  BasicLinkCellComponent,
  TranslatedCellComponent,
  CallToActionCellComponent,
  CallToActionHeaderComponent,
  DateCellComponent,
  NumberCellComponent,
  CountCellComponent,
  RichVariableValueCellComponent,
  DynamicIconCellComponent,
  CommentCellComponent,
  SeverityCellComponent,
  IconCellComponent,
  FollowedNumberCellComponent,
  ActiveEventsCellComponent,
  LinkedCellComponent,
  VariableDispatchCellComponent,
  ArrayOfObjectsCellComponent,
  BooleanCellComponent,
  AssetVariableCellComponent,
  LastCommandStatusCellComponent,
  CallToActionMenuItemComponent,
  UserCellComponent,
  EntityCellComponent,
  ColorCellComponent,
  TopicSelfSubscriptionCellComponent,
  HeaderShellComponent,
  CronReadableCellComponent,
  TagCellComponent,
  GridManagerAdminPopupComponent,
  GridManagerComponent,
  GridPageComponent,
  GridManagerUserPopupComponent,
  EventDurationCellComponent,
  AddressAccuracyCellComponent,
  OutgoingConnectorCellComponent,
  AssetTemplateCellComponent
];

const GRID_COMPONENTS = [GridEngineComponent, ...HEADER_COMPONENTS, ...CELL_COMPONENTS, PaginatorComponent, GridExportDialogComponent, GridExportComponent];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    AgGridModule,
    EventSeverityDisplayModule,
    DragDropModule,
    FilterEngineModule,
    StoreModule.forFeature(fromGrids.gridsFeatureKey, fromGrids.reducers),
    EffectsModule.forFeature([GridsEffects]),
    SubscriptionButtonModule,
    ...FILTER_COMPONENTS,
    InfoDisplayPipe,
    NumberFormatPipe,
    DateFormatPipe,
    ValueUnitFormatPipe,
    CronDisplayPipe,
    UserNamePipe
  ],
  declarations: [GRID_COMPONENTS, AssetVariableThresholdCellComponent, EmailTemplateCellComponent, DiagnosticVariableCellComponent],
  exports: [GridEngineComponent, HEADER_COMPONENTS, CELL_COMPONENTS, PaginatorComponent, FILTER_COMPONENTS],
  providers: [CronDisplayPipe, DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, UserNamePipe, ValueUnitFormatPipe, GridEngineSettingsService]
})
export class GridEngineModule {
  static withSettings(settings: {
    assetVariableConfigureReadPopupService: Type<AbstractAssetVariableConfigureReadPopupService>;
  }): ModuleWithProviders<GridEngineModule> {
    return {
      ngModule: GridEngineModule,
      providers: [
        {
          provide: AbstractAssetVariableConfigureReadPopupService,
          useClass: settings.assetVariableConfigureReadPopupService
        }
      ]
    };
  }

  static withTestingSettings(): ModuleWithProviders<GridEngineModule> {
    return {
      ngModule: GridEngineModule,
      providers: [
        {
          provide: AbstractAssetVariableConfigureReadPopupService,
          useValue: { updateAssetVariableThresholds: () => noop() }
        }
      ]
    };
  }
}
