import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { PopupComponent } from '@iot-platform/iot-platform-ui';

import { BaseUser, BusinessProfile, Organization, Role, TagCategory } from '@iot-platform/models/common';

import { BehaviorSubject } from 'rxjs';

import { DialogOrganizationAddFormComponent } from '../../../../components/dialogs/dialog-organization-add-form/dialog-organization-add-form.component';
import { DialogOrganizationEditFormComponent } from '../../../../components/dialogs/dialog-organization-edit-form/dialog-organization-edit-form.component';
import { DialogRoleEditFormComponent } from '../../../../components/dialogs/dialog-role-edit-form/dialog-role-edit-form.component';

@Component({
  selector: 'iot4bos-backoffice-ui-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss', '../../../../style/admin.style.scss']
})
export class OrganizationDetailComponent implements OnInit, OnChanges {
  @Input() organization: Organization;
  @Input() roles: Role[];
  @Input() businessProfiles: BusinessProfile[];
  @Input() tags: TagCategory[];
  @Input() administrators: BaseUser[];
  @Input() isTopLevelAdmin = false;
  @Input() isCurrentUserAdmin: boolean;
  @Input() adminConceptsFromEntitySession: [string];

  @Input() selectedRole: Role;
  @Input() businessProfilesPendingStatus: boolean;
  @Input() rolesPendingStatus: boolean;
  @Input() tagsPendingStatus: boolean;
  @Input() administratorsPendingStatus: boolean;

  @Output() authorizationsChanged: EventEmitter<Role> = new EventEmitter();
  @Output() selectRole: EventEmitter<Role> = new EventEmitter();
  @Output() renameRole: EventEmitter<Role> = new EventEmitter();
  @Output() addBusinessProfile: EventEmitter<Organization> = new EventEmitter();
  @Output() addRole: EventEmitter<Organization> = new EventEmitter();
  @Output() addAdministratorToOrganization: EventEmitter<boolean> = new EventEmitter();
  @Output() updateOrganization: EventEmitter<Organization> = new EventEmitter();
  @Output() addOrganization: EventEmitter<Organization> = new EventEmitter();
  @Output() deleteOrganization: EventEmitter<Organization> = new EventEmitter();
  @Output() deleteBusinessProfile: EventEmitter<BusinessProfile> = new EventEmitter();
  @Output() selectBusinessProfile: EventEmitter<BusinessProfile> = new EventEmitter();
  @Output() deleteRole: EventEmitter<Role> = new EventEmitter();
  @Output() addTags: EventEmitter<TagCategory> = new EventEmitter<TagCategory>();
  @Output() editTags: EventEmitter<TagCategory> = new EventEmitter<TagCategory>();
  @Output() manageTags: EventEmitter<{
    concept: string;
    organization: Organization;
    conceptList: string[];
  }> = new EventEmitter();
  @Output() manageConcepts: EventEmitter<{
    organization: Organization;
    adminConceptsFromEntitySession: any[];
  }> = new EventEmitter();
  @Output() lockUnlockOrganization: EventEmitter<{ organization: Organization; isLocked: boolean }> = new EventEmitter<{
    organization: Organization;
    isLocked: boolean;
  }>();

  conceptList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(['site', 'asset', 'device']);
  topLevelAdmin$: BehaviorSubject<{ isAdmin: boolean; topLevelEntitySelected: boolean }> = new BehaviorSubject<{
    isAdmin: boolean;
    topLevelEntitySelected: boolean;
  }>({ isAdmin: false, topLevelEntitySelected: false });

  canCreateOrganization: boolean;
  canUpdateOrganization: boolean;
  canDeleteOrganization: boolean;

  canCreateBusinessProfile: boolean;
  canUpdateBusinessProfile: boolean;
  canDeleteBusinessProfile: boolean;
  canReadBusinessProfile: boolean;

  canCreateTags: boolean;
  canReadTags: boolean;
  canUpdateTags: boolean;
  canDeleteTags: boolean;

  constructor(
    private readonly dialog: MatDialog,
    private readonly authz: AuthorizationService
  ) {
    this.canCreateOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.CREATE);
    this.canUpdateOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.UPDATE);
    this.canDeleteOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.DELETE);
    this.canCreateBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.CREATE);
    this.canUpdateBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
    this.canDeleteBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.DELETE);
    this.canReadBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.READ);
    this.canCreateTags = this.authz.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.CREATE);
    this.canReadTags = this.authz.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.READ);
    this.canUpdateTags = this.authz.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.UPDATE);
    this.canDeleteTags = this.authz.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.DELETE);
  }

  ngOnInit() {
    this.topLevelAdmin$.subscribe((value: { isAdmin: boolean; topLevelEntitySelected: boolean }) => {
      if (value.topLevelEntitySelected === true && value.isAdmin === true) {
        this.conceptList$.next(['site', 'asset', 'device', 'event']);
      } else {
        this.conceptList$.next(['site', 'asset', 'device']);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('isTopLevelAdmin') && changes.isTopLevelAdmin.currentValue) {
      this.topLevelAdmin$.next({ ...this.topLevelAdmin$.getValue(), isAdmin: changes.isTopLevelAdmin.currentValue });
    }
    if (changes.hasOwnProperty('organization') && changes.organization.currentValue) {
      this.topLevelAdmin$.next({
        ...this.topLevelAdmin$.getValue(),
        topLevelEntitySelected: changes.organization.currentValue.parentId === null
      });
    }
  }

  onSelectRole(selected: Role) {
    this.selectRole.emit(selected);
  }

  onRenameRole(toRename: Role) {
    this.renameRole.emit(toRename);
  }

  onAuthorizationsChanged(role: Role) {
    this.dialog
      .open(DialogRoleEditFormComponent, {
        data: {
          role: Object.assign({}, role, { rights: { ...role.rights } }),
          adminConceptsFromEntitySession: this.adminConceptsFromEntitySession,
          isAdmin: this.isCurrentUserAdmin
        },
        disableClose: true,
        width: '550px'
      })
      .afterClosed()
      .subscribe((roleToUpdate) => {
        if (roleToUpdate) {
          this.authorizationsChanged.emit(roleToUpdate);
        }
      });
  }

  saveEntityName(newName: string) {
    this.organization.name = newName;
    this.updateOrganization.emit(this.organization);
  }

  onUpdateConcepts(concepts: [string]) {
    this.organization.adminConcepts = concepts;
    this.updateOrganization.emit(this.organization);
  }

  onSelectBusinessProfile(businessProfile: BusinessProfile): void {
    this.selectBusinessProfile.emit(businessProfile);
  }

  onDeleteBusinessProfile(businessProfile: BusinessProfile) {
    this.deleteBusinessProfile.emit(businessProfile);
  }

  onDeleteRole(role: Role) {
    this.deleteRole.emit(role);
  }

  editOrganizationName(organization: Organization) {
    this.dialog
      .open(DialogOrganizationEditFormComponent, {
        data: {
          organization,
          parentOrganizationId: this.organization.parentId
        },
        width: '500px'
      })
      .afterClosed()
      .subscribe((organizationToUpdate) => {
        if (organizationToUpdate) {
          this.updateOrganization.emit(organizationToUpdate);
        }
      });
  }

  addNewOrganization() {
    this.dialog
      .open(DialogOrganizationAddFormComponent, {
        data: {
          parentOrganizationName: this.organization.name,
          parentOrganizationId: this.organization.id
        },
        width: '500px'
      })
      .afterClosed()
      .subscribe((newOrganization) => {
        if (newOrganization) {
          this.addOrganization.emit(newOrganization);
        }
      });
  }

  onDeleteOrganizationButtonClick(organization: Organization) {
    this.confirmDeletion(organization);
  }

  onTagSave(tagCategory: TagCategory) {
    this.addTags.emit(tagCategory);
  }

  onManageTags(concept: string) {
    this.manageTags.emit({ concept, organization: this.organization, conceptList: this.conceptList$.getValue() });
  }

  getTagCategoriesByConcept(concept: string): TagCategory[] {
    return this.tags.filter((t) => t.concept.toLowerCase() === concept);
  }

  getTagsTotal(tags: TagCategory[]) {
    return tags.reduce((acc, value) => (acc = acc + value.labels.length), 0);
  }

  onLockUnlockOrganization(isLocked: boolean): void {
    if (this.isTopLevelAdmin) {
      this.lockUnlockOrganization.emit({
        organization: this.organization,
        isLocked
      });
    }
  }

  private confirmDeletion(organizationToDelete: Organization) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: organizationToDelete.name }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteOrganization.emit(organizationToDelete);
      }
    });
  }
}
