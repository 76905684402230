<div (click)="onClick()" [ngClass]="{ 'breadcrumb-item': false, passive: true }" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <!--<iot-platform-ui-icon [ngClass]="['breadcrumb-item-icon', 'breadcrumb-site-icon']" name="site" color="#4f78b7"></iot-platform-ui-icon>-->
  <mat-icon color="accent" svgIcon="site"></mat-icon>
  <div fxLayout="column">
    @if (site.entity && site.entity.name) {
      <span class="secondary" fxLayoutAlign="start center">
        {{ site.entity.name }}
      </span>
    }
    @if (site.name) {
      <span class="main" fxLayoutAlign="start center">{{ site.name }}</span>
    }
  </div>
</div>
