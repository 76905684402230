import { Injectable } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { IotMapFacade } from '@iot-platform/iot-platform-maps';
import { TagCategory } from '@iot-platform/models/common';
import { Asset, AssetCommandResponse, I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatuses } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { AssetsService } from '@iot-platform/shared/services';
import { UserPreferencesService } from '@iot-platform/users';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import * as fromNavigation from '../../../../containers/+state/reducers';
import { AssetsActions, AssetVariablesActions } from '../actions';

@Injectable()
export class AssetsEffects {
  addAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.addOne),
      concatMap((action) =>
        this.assetsService.post(action.toAdd).pipe(
          map((asset: Asset) => AssetsActions.addOneSuccess({ added: asset })),
          catchError((error) => of(AssetsActions.addOneFailure({ error })))
        )
      )
    )
  );

  addAssetByTemplateId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.addOneByTemplateId),
      concatMap((action) =>
        this.assetsService.addAssetByTemplateId(action.assetTemplateId, action.siteId).pipe(
          map((added: Asset) => AssetsActions.addOneByTemplateIdSuccess({ added })),
          catchError((error) => of(AssetsActions.addOneByTemplateIdFailure({ error })))
        )
      )
    )
  );

  loadAssetById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getOne),
      switchMap((action) =>
        this.assetsService.getAssetById(action.id).pipe(
          map((asset: Asset) => AssetsActions.getOneSuccess({ element: asset })),
          catchError((error) => of(AssetsActions.getOneFailure({ error })))
        )
      )
    )
  );

  loadAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getMany),
      switchMap((action) =>
        this.assetsService.getAll(action.request).pipe(
          map((response) => AssetsActions.getManySuccess({ response })),
          catchError((error) => of(AssetsActions.getManyFailure({ error })))
        )
      )
    )
  );

  loadMVAssetSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadMVAssetsSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => AssetsActions.loadMVAssetsSettingsSuccess({ settings })),
          catchError((error) => of(AssetsActions.loadMVAssetsSettingsFailure({ error })))
        )
      )
    )
  );

  saveMVAssetSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.saveMVAssetsSettings),
      switchMap((action) =>
        this.userPrefService.saveMySettingsAndGetOnlyActive(action.name, action.values).pipe(
          map((response) => AssetsActions.saveMVAssetsSettingsSuccess({ settings: response })),
          catchError((error) => of(AssetsActions.saveMVAssetsSettingsFailure({ error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.selectOne),
      switchMap((action) =>
        this.assetsService.saveTableState(action.toSelect).pipe(
          map((selected: Asset) => AssetsActions.selectOneSuccess({ selected })),
          catchError((error) => of(AssetsActions.selectOneFailure({ error })))
        )
      )
    )
  );

  updateAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.updateOne),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultAssetsGrid)),
      concatMap(([action, grid]) =>
        this.assetsService.put(action.toUpdate).pipe(
          concatMap((asset: Asset) => {
            const DEFAULT_ACTIONS = [AssetsActions.updateOneSuccess({ updated: asset })];
            return grid?.id
              ? [
                  ...DEFAULT_ACTIONS,
                  GridsDbActions.updateItemInGridData({
                    gridId: grid.id,
                    item: asset,
                    concept: 'assets'
                  })
                ]
              : DEFAULT_ACTIONS;
          }),
          catchError((error) => of(AssetsActions.updateOneFailure({ error })))
        )
      )
    )
  );

  updateAssetFromMV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.updateOneFromMV),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultAssetsGrid)),
      concatMap(([action, grid]) =>
        this.assetsService.put(action.assetToUpdate).pipe(
          concatMap((asset: Asset) => {
            const DEFAULT_ACTIONS = [AssetsActions.updateOneFromMVSuccess({ asset }), GridsDbActions.loadGridData({ request: action.request })];
            return grid?.id
              ? [
                  ...DEFAULT_ACTIONS,
                  GridsDbActions.updateItemInGridData({
                    gridId: grid.id,
                    item: asset,
                    concept: 'assets'
                  })
                ]
              : DEFAULT_ACTIONS;
          }),
          catchError((error) => of(AssetsActions.updateOneFromMVFailure({ error })))
        )
      )
    )
  );

  deleteAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.deleteOne),
      concatMap((action) =>
        this.assetsService.delete(action.toDelete).pipe(
          map(() => AssetsActions.deleteOneSuccess({ deleted: action.toDelete })),
          catchError((error) => of(AssetsActions.deleteOneFailure({ error })))
        )
      )
    )
  );

  deleteAssetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.deleteOneSuccess),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultAssetsGrid)),
      mergeMap(([action, grid]) =>
        grid?.id
          ? of(
              GridsDbActions.removeItemInGridData({
                gridId: grid.id,
                item: action.deleted
              })
            )
          : of(null)
      )
    )
  );

  loadSiteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getSiteById),
      switchMap((action) =>
        this.assetsService.getSiteById(action.siteId).pipe(
          map((site) => AssetsActions.getSiteByIdSuccess({ site })),
          catchError((error) => of(AssetsActions.getSiteByIdFailure({ error })))
        )
      )
    )
  );

  loadTagsByAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getTags),
      switchMap((action) =>
        this.assetsService.getTagsByAssetId(action.assetId).pipe(
          map((tags: TagCategory[]) => AssetsActions.getTagsSuccess({ tags })),
          catchError((error) => of(AssetsActions.getTagsFailure({ error })))
        )
      )
    )
  );

  updateTagsByAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.updateTagsByAssetId),
      concatMap((action) =>
        this.assetsService.putTagsByAssetId(action.assetId, action.tags).pipe(
          map((tags: TagCategory[]) => AssetsActions.updateTagsByAssetIdSuccess({ tags })),
          catchError((error) => of(AssetsActions.updateTagsByAssetIdFailure({ error })))
        )
      )
    )
  );

  assignProductToAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.assignProductToAsset),
      switchMap((action) =>
        this.assetsService.assignProductToAsset(action.product, action.asset).pipe(
          map((asset: Asset) => AssetsActions.assignProductToAssetSuccess({ asset })),
          catchError((error) => of(AssetsActions.assignProductToAssetFailure({ error })))
        )
      )
    )
  );

  removeProductFromAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.removeProductFromAsset),
      concatMap((action) =>
        this.assetsService.removeProductFromAsset(action.product, action.asset).pipe(
          map((asset: Asset) => AssetsActions.removeProductFromAssetSuccess({ product: action.product, asset })),
          catchError((error) => of(AssetsActions.removeProductFromAssetFailure({ error })))
        )
      )
    )
  );

  navigateToNewlyAddedAsset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.addOneSuccess, AssetsActions.addOneByTemplateIdSuccess),
        tap((action) => {
          this.navigation.selectAssetAvecLeSite(action.added);
        })
      ),
    { dispatch: false }
  );

  sendCommand = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.sendCommand),
      switchMap((action) =>
        this.assetsService.sendCommand(action.asset.id, action.command).pipe(
          concatMap((response: AssetCommandResponse) => [AssetsActions.sendCommandSuccess({ response })]),
          catchError((error) => of(AssetsActions.sendCommandFailure({ error })))
        )
      )
    )
  );

  bulkAddOrRemoveTagByAssetsIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.bulkAddOrRemoveTag),
      concatMap((action) =>
        this.assetsService.bulkAddOrRemoveTag(action.isAddition, action.assetsIds, action.tagLabelId).pipe(
          map((response: I4BBulkOperationApiResponse) =>
            action.isAddition ? AssetsActions['bulkAddTag-']({ response }) : AssetsActions['bulkRemoveTag-']({ response })
          ),
          catchError((error) => of(action.isAddition ? AssetsActions.bulkAddTagFailure({ error }) : AssetsActions.bulkRemoveTagFailure({ error })))
        )
      )
    )
  );

  bulkActionsThenReloadGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions['bulkAddTag-'], AssetsActions['bulkRemoveTag-'], AssetsActions.bulkSendCommandSuccess),
      withLatestFrom(this.store.select(fromGrids.getDefaultAssetsGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewAssets)),
      filter(([_, grid, __]) => !!grid),
      map(([_, grid, filters]) =>
        GridsDbActions.loadGridData({
          request: {
            filters,
            limit: grid?.data.response.pagination.limit,
            concept: grid?.masterview.toLowerCase(),
            page: grid?.data.response.pagination.currentPage,
            variables: grid?.gridOptions.variableNames,
            tags: grid?.gridOptions.tagIds,
            endPoint: grid?.gridOptions.endPoint
          }
        })
      )
    )
  );

  displaySuccessAfterBulkAddOrRemoveTagByAssetsIds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions['bulkAddTag-'], AssetsActions['bulkRemoveTag-']),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  bulkSendCommandByAssetsIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.bulkSendCommand),
      concatMap((action) =>
        this.assetsService.bulkSendCommand(action.assetsIds, action.command.command).pipe(
          map((response: AssetCommandResponse) => AssetsActions.bulkSendCommandSuccess({ response })),
          catchError((error) => of(AssetsActions.bulkSendCommandFailure({ error })))
        )
      )
    )
  );

  operationThatTriggerReloadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssetsActions.assignProductToAssetSuccess,
        AssetsActions.removeProductFromAssetSuccess,
        AssetVariablesActions.addOneSuccess,
        AssetVariablesActions['deleteMany-'],
        AssetVariablesActions.updateOneSuccess,
        AssetVariablesActions.updateFollowedAssetVariableSuccess
      ),
      concatLatestFrom(() => this.store.select(fromNavigation.getSelectedAsset)),
      map(([_, asset]) => AssetsActions.getOne({ id: asset.id as string }))
    )
  );

  loadAssetSuccessThenUpdateItemInAllGrids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.getOneSuccess),
      map((action) => GridsDbActions.updateItemInAllGridsData({ updatedItem: action.element, concept: 'assets' }))
    )
  );

  setCurrentFavoriteView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.setCurrentFavoriteView),
        filter((action) => get(action, 'masterView') === 'assets'),
        tap((action) => {
          this.mapFacade.getAll({
            concept: action.masterView,
            displayMode: 'default',
            filters: get(action, 'favoriteView.filters', [])
          });
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetsActions.addOneSuccess,
          AssetsActions.saveMVAssetsSettingsSuccess,
          AssetsActions.updateOneSuccess,
          AssetsActions.deleteOneSuccess,
          AssetsActions.updateTagsByAssetIdSuccess,
          AssetsActions.assignProductToAssetSuccess,
          AssetsActions.removeProductFromAssetSuccess,
          AssetsActions.sendCommandSuccess,
          AssetsActions.bulkSendCommandSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetsActions.addOneFailure,
          AssetsActions.getOneFailure,
          AssetsActions.getTagsFailure,
          AssetsActions.updateTagsByAssetIdFailure,
          AssetsActions.getManyFailure,
          AssetsActions.selectOneFailure,
          AssetsActions.updateOneFailure,
          AssetsActions.deleteOneFailure,
          AssetsActions.getSiteByIdFailure,
          AssetsActions.assignProductToAssetFailure,
          AssetsActions.removeProductFromAssetFailure,
          AssetsActions.saveMVAssetsSettingsFailure,
          AssetsActions.loadMVAssetsSettingsFailure,
          AssetsActions.sendCommandFailure,
          AssetsActions.bulkAddTagFailure,
          AssetsActions.bulkRemoveTagFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetsActions.getMany,
          AssetsActions.loadMVAssetsSettings,
          AssetsActions.saveMVAssetsSettings,
          AssetsActions.getOne,
          AssetsActions.updateTagsByAssetId,
          AssetsActions.getTags,
          AssetsActions.getSiteById,
          AssetsActions.deleteOne,
          AssetsActions.updateOne,
          AssetsActions.addOne,
          AssetsActions.addOneByTemplateId,
          AssetsActions.assignProductToAsset,
          AssetsActions.removeProductFromAsset,
          AssetsActions.sendCommand,
          AssetsActions.bulkAddOrRemoveTag
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetsActions.addOneSuccess,
          AssetsActions.addOneFailure,
          AssetsActions.getManySuccess,
          AssetsActions.getManyFailure,
          AssetsActions.getOneSuccess,
          AssetsActions.getOneFailure,
          AssetsActions.getSiteByIdSuccess,
          AssetsActions.getSiteByIdFailure,
          AssetsActions.updateOneSuccess,
          AssetsActions.updateOneFailure,
          AssetsActions.getTagsSuccess,
          AssetsActions.getTagsFailure,
          AssetsActions.deleteOneSuccess,
          AssetsActions.deleteOneFailure,
          AssetsActions.updateTagsByAssetIdSuccess,
          AssetsActions.updateTagsByAssetIdFailure,
          AssetsActions.assignProductToAssetFailure,
          AssetsActions.assignProductToAssetSuccess,
          AssetsActions.removeProductFromAssetFailure,
          AssetsActions.removeProductFromAssetSuccess,
          AssetsActions.loadMVAssetsSettingsSuccess,
          AssetsActions.loadMVAssetsSettingsFailure,
          AssetsActions.saveMVAssetsSettingsSuccess,
          AssetsActions.saveMVAssetsSettingsFailure,
          AssetsActions.sendCommandSuccess,
          AssetsActions.sendCommandFailure,
          AssetsActions['bulkAddTag-'],
          AssetsActions.bulkAddTagFailure,
          AssetsActions['bulkRemoveTag-'],
          AssetsActions.bulkRemoveTagFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly assetsService: AssetsService,
    private readonly userPrefService: UserPreferencesService,
    private readonly navigation: NavigationApi,
    private readonly notificationService: NotificationService,
    private readonly mapFacade: IotMapFacade
  ) {}
}
