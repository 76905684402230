import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class XmqttDeviceButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.DEVICES,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          bulkActions: [
            {
              order: 1,
              key: 'bulkEditDevices',
              label: 'DEVICES.INFO_FORM_POPUP.EDIT_DEVICES',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            }
            /*
            {
              order: 2,
              key: 'bulkEditDeviceFiles',
              label: 'DEVICES.FILES_POPUP.TITLE',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            }
             */
          ],
          singleActions: [
            {
              order: 1,
              key: 'editDevice',
              label: 'DEVICES.TABLE_CONTENT.BUTTONS.EDIT_DEVICE',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            }
            /*
            {
              order: 2,
              key: 'editDeviceFiles',
              label: 'DEVICES.FILES_POPUP.TITLE',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            }
            */
          ],
          visibleConditions: [],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}
