import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AssetVariable, Formula } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { VariableParameterComponent } from '../variable-parameter/variable-parameter.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatInputModule, VariableParameterComponent],
  selector: 'shared-iot4bos-asset-ui-multiple-variables-parameters',
  templateUrl: './multiple-variables-parameters.component.html'
})
export class MultipleVariablesParametersComponent implements OnChanges, OnDestroy {
  @Input() assetVariable?: AssetVariable;
  @Input() initialFormula?: Formula;
  @Input() initialSelectedVariables?: AssetVariable[];
  @Input() readonly = false;

  @Output() dispatchFormula: EventEmitter<Formula | null> = new EventEmitter();
  @Output() updateSelectedAssetVariables: EventEmitter<AssetVariable[]> = new EventEmitter();

  parametersForm: UntypedFormGroup = new UntypedFormGroup({});

  formulaParameters: { name: string; unit?: string }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firstEmptyParameter?: any;
  invalidUnitTooltip = '';
  placeholder = '';

  assetVariableFormula: Formula = { model: null, parameters: {}, srcVariables: {} };

  subscriptions: Subscription[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.assetVariable) {
      this.setFirstEmptyParameter();

      if (changes.assetVariable.currentValue && this.parametersForm && this.firstEmptyParameter) {
        this.parametersForm.markAsTouched();
        this.assignAssetVariableToFirstEmptyParameter(changes.assetVariable.currentValue);
        this.setFirstEmptyParameter();
        this.sendSelectedAssetVariables();
      } else if (!changes.assetVariable.currentValue && !changes.assetVariable.firstChange && this.parametersForm) {
        this.parametersForm.reset();
        this.setFirstEmptyParameter();
        this.sendSelectedAssetVariables();
      }
    }
  }

  setFirstEmptyParameter(): void {
    this.firstEmptyParameter = this.formulaParameters.find((parameter) => this.parametersForm.controls[parameter.name]?.value === null);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  assignAssetVariableToFirstEmptyParameter(assetVariable: AssetVariable): void {}

  sendSelectedAssetVariables(): void {
    this.updateSelectedAssetVariables.emit(
      this.formulaParameters.reduce((acc: AssetVariable[], parameter) => {
        if (this.parametersForm.controls[parameter.name]?.value) {
          acc.push(this.parametersForm.controls[parameter.name].value);
        }
        return acc;
      }, [])
    );
  }

  clearFormControl(formControlName: string): void {
    this.parametersForm.controls[formControlName]?.reset();
    this.parametersForm.markAsTouched();
    this.setFirstEmptyParameter();
    this.sendSelectedAssetVariables();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createFormula(): void {}

  sendFormula(): void {
    if (this.parametersForm.valid) {
      this.createFormula();
      this.dispatchFormula.emit(this.assetVariableFormula);
    } else {
      this.dispatchFormula.emit(null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
