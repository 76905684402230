import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'grid-engine-event-duration-cell',
  template: `
    @if (params?.value?.seconds >= 0) {
      <div fxLayoutAlign="start center" fxLayoutGap="5px" [class]="params?.value?.color">
        @if (params?.value?.days) {
          <span>{{ params?.value?.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
        }
        @if (params?.value?.hours) {
          <span>{{ params?.value?.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
        }
        @if (params?.value?.minutes) {
          <span>{{ params?.value?.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
        }
        @if (params?.value?.seconds) {
          <span>{{ params?.value?.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
        }
      </div>
    } @else {
      <span class="event-timeline-time">N/A</span>
    }
  `,
  styleUrls: ['event-duration-cell.component.scss']
})
export class EventDurationCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
