import { Component, OnInit } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormulaType } from '@iot-platform/models/common';
import { AssetVariable, Formula, ShortfallSrcVariables } from '@iot-platform/models/i4b';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { MultipleVariablesParametersComponent } from '../multiple-variables-parameters/multiple-variables-parameters.component';
import { VariableParameterComponent } from '../variable-parameter/variable-parameter.component';

interface ShortfallParameters {
  name: string;
  unitCategory: string;
  unit: undefined;
}

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatInputModule, VariableParameterComponent],
  selector: 'shared-iot4bos-asset-ui-shortfall-parameters-form',
  templateUrl: '../multiple-variables-parameters/multiple-variables-parameters.component.html'
})
export class ShortfallParametersFormComponent extends MultipleVariablesParametersComponent implements OnInit {
  unitCategoryAllowed = 'flowRate';
  unitsAllowed = [];

  formulaParameters: ShortfallParameters[] = [
    { name: 'contractualFlow', unitCategory: this.unitCategoryAllowed, unit: undefined },
    { name: 'productionFlow', unitCategory: this.unitCategoryAllowed, unit: undefined },
    { name: 'totalFlow', unitCategory: this.unitCategoryAllowed, unit: undefined }
  ];

  assetVariableFormulaSrcVariables: ShortfallSrcVariables = {
    'contractual-flow': {
      name: 'contractual-flow',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    'production-flow': {
      name: 'production-flow',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    'total-flow': {
      name: 'total-flow',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    }
  };

  assetVariableFormula: Formula = {
    model: FormulaType.SHORTFALL,
    parameters: {},
    srcVariables: this.assetVariableFormulaSrcVariables
  };

  invalidUnitTooltip = 'ASSETS.VARIABLE_FORM.SHORTFALL_FORM.INVALID_UNIT_TOOLTIP';
  placeholder = 'ASSETS.VARIABLE_FORM.SHORTFALL_FORM.PLACEHOLDERS.';

  constructor(private readonly assetVariableService: AssetVariablesService) {
    super();
  }

  get contractualFlow(): AbstractControl {
    return this.parametersForm.get('contractualFlow');
  }

  get productionFlow(): AbstractControl {
    return this.parametersForm.get('productionFlow');
  }

  get totalFlow(): AbstractControl {
    return this.parametersForm.get('totalFlow');
  }

  ngOnInit(): void {
    const srcVariables = this.initialFormula?.srcVariables as ShortfallSrcVariables;

    this.parametersForm = new UntypedFormGroup({
      contractualFlow: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables['contractual-flow']?.variableId) : null,
        [Validators.required]
      ),
      productionFlow: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables['production-flow']?.variableId) : null,
        [Validators.required]
      ),
      totalFlow: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables['total-flow']?.variableId) : null,
        [Validators.required]
      )
    });
    this.checkVariableUnitCategory('contractualFlow');
    this.checkVariableUnitCategory('productionFlow');
    this.checkVariableUnitCategory('totalFlow');
    this.parametersForm.markAsUntouched();

    this.setFirstEmptyParameter();

    this.subscriptions.push(
      this.parametersForm.valueChanges.subscribe(() => {
        this.sendFormula();
      }),
      this.parametersForm.statusChanges.subscribe(() => {
        this.sendFormula();
      }),
      this.assetVariableService
        .getUnits()
        .subscribe((unitList) => (this.unitsAllowed = unitList.find((unit) => unit?.name === this.unitCategoryAllowed)?.units ?? []))
    );
    this.sendFormula();
  }

  checkVariableUnitCategory(parameterName: string): void {
    if (!this?.unitsAllowed.find((unit) => unit === this[parameterName].value?.unit)) {
      this[parameterName].setErrors({ invalidUnit: true });
    } else {
      this[parameterName].setErrors(null);
    }
  }

  assignAssetVariableToFirstEmptyParameter(assetVariable: AssetVariable): void {
    if (!!this.firstEmptyParameter && !this.formulaParameters.find((parameter) => this[parameter.name].value?.id === assetVariable.id)) {
      this[this.firstEmptyParameter.name].setValue(assetVariable);
      this.checkVariableUnitCategory(this.firstEmptyParameter.name);
    }
  }

  createFormula(): void {
    this.assetVariableFormulaSrcVariables = {
      'contractual-flow': {
        ...this.assetVariableFormulaSrcVariables['contractual-flow'],
        variableId: this.contractualFlow.value.id,
        originId: this.contractualFlow.value.asset.id
      },
      'production-flow': {
        ...this.assetVariableFormulaSrcVariables['production-flow'],
        variableId: this.productionFlow.value.id,
        originId: this.productionFlow.value.asset.id
      },
      'total-flow': {
        ...this.assetVariableFormulaSrcVariables['total-flow'],
        variableId: this.totalFlow.value.id,
        originId: this.totalFlow.value.asset.id
      }
    };

    this.assetVariableFormula = { ...this.assetVariableFormula, srcVariables: this.assetVariableFormulaSrcVariables };
  }
}
