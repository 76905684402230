import { Component } from '@angular/core';
import { Filter } from '@iot-platform/models/common';
import { TimezoneAutocompleteMultipleSelectsComponent } from '../../../timezone-autocomplete-multiple-selects/timezone-autocomplete-multiple-selects.component';
import { TimezoneAutocompleteComponent } from '../../../timezone-autocomplete/timezone-autocomplete.component';
import { AbstractFilterEngineFieldComponent } from '../abstract-filter-engine-field.component';

@Component({
  standalone: true,
  imports: [TimezoneAutocompleteMultipleSelectsComponent, TimezoneAutocompleteComponent],
  selector: 'iot-platform-ui-timezone-autocomplete-field',
  templateUrl: './timezone-autocomplete-field.component.html'
})
export class TimezoneAutocompleteFieldComponent extends AbstractFilterEngineFieldComponent<any> {
  onSelectionChange(value: string): void {
    const data = this.data();
    const newFilter: Filter = {
      value,
      label: value,
      criteriaKey: data.criteriaKey,
      criteriaLabel: data.criteriaLabel
    };
    this.dispatchFilterEvent(newFilter);
  }
}
