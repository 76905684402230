import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { fromAuth } from '@iot-platform/auth';
import { ENVIRONMENT } from '@iot-platform/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, Entity, Environment, Pagination } from '@iot-platform/models/common';
import { Asset, AssetTemplate } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetTemplatesService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly store: Store = inject(Store);

  addAssetTemplate(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .post<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}`, assetTemplate)
      .pipe(map((template) => ({ ...template, isEditable: true })));
  }

  updateAssetTemplate(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .patch<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplate.id}`, {
        name: assetTemplate.name,
        description: assetTemplate.description
      })
      .pipe(map((template) => ({ ...template, isEditable: true })));
  }

  updateTemplate(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .patch<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplate.id}`, {
        template: {
          name: assetTemplate.template.name,
          businessId: assetTemplate.template.businessId,
          shipTo: assetTemplate.template.shipTo,
          deliveryDate: assetTemplate.template.deliveryDate,
          quantity1: assetTemplate.template.quantity1,
          quantity2: assetTemplate.template.quantity2,
          description: assetTemplate.template.description,
          imageUrl: assetTemplate.template.imageUrl
        }
      })
      .pipe(map((template) => ({ ...template, isEditable: true })));
  }

  updateAssetTemplateTags(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .patch<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplate.id}`, {
        template: {
          tags: assetTemplate.template.tags
        }
      })
      .pipe(map((template) => ({ ...template, isEditable: true })));
  }

  configureAssetTemplateProducts(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .patch<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplate.id}`, {
        template: {
          product1: assetTemplate.template.product1,
          product2: assetTemplate.template.product2
        }
      })
      .pipe(map((template) => ({ ...template, isEditable: true })));
  }

  removeAssetTemplateProducts(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .patch<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplate.id}`, {
        template: {
          products: null,
          product1: null,
          product2: null
        }
      })
      .pipe(map((template) => ({ ...template, isEditable: true })));
  }

  configureAssetTemplateOptionalProperties(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .patch<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplate.id}`, {
        template: {
          optionalProperties: assetTemplate.template.optionalProperties
        }
      })
      .pipe(map((template) => ({ ...template, isEditable: true })));
  }

  updateAssetTemplateVariables(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .patch<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplate.id}`, {
        template: {
          variables: assetTemplate.template.variables
        }
      })
      .pipe(map((template) => ({ ...template, isEditable: true })));
  }

  deleteAssetTemplate(assetTemplate: AssetTemplate): Observable<AssetTemplate> {
    return this.http
      .delete<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplate.id}`)
      .pipe(map((_) => assetTemplate));
  }

  getAssetTemplateById(assetTemplateId: string): Observable<AssetTemplate> {
    return this.http.get<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplateId}`).pipe(
      withLatestFrom(this.store.select(fromAuth.selectSelectedEntityForSession)),
      map(([template, sessionEntity]) => ({ ...template, isEditable: AssetTemplatesService.calculateIsEditable(template, sessionEntity) }))
    );
  }

  isNameUnique(assetTemplateName: string, entityId: string): Observable<boolean> {
    let params: HttpParams = new HttpParams();
    params = params.set('page', '0');
    params = params.append('name', assetTemplateName);
    params = params.append('entityId', entityId);
    return this.http
      .get<CommonApiListResponse<AssetTemplate>>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}`, { params })
      .pipe(map((data: CommonApiListResponse<AssetTemplate>) => data.page.total === 0));
  }

  getAttachedAssets(request: CommonApiRequest): Observable<CommonApiResponse<Asset, Pagination>> {
    const params: HttpParams = ApiHelpers.getHttpParams(request);
    return this.http
      .get<CommonApiListResponse<Asset>>(`${this.environment.api.url}${this.environment.api.endpoints.assets}`, { params })
      .pipe(map((response: CommonApiListResponse<Asset>) => ApiHelpers.getHttpResponse<Asset>(response)));
  }

  static calculateIsEditable(template: AssetTemplate, sessionEntity: Entity): boolean {
    const found = template.entityPath.find((entity: Entity) => entity.id === sessionEntity.id);
    return template.entity.id === sessionEntity.id || !!found;
  }
}
