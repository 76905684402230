<form [formGroup]="parametersForm" fxLayout="column" fxLayoutGap="4px" style="margin-top: 10px">
  <mat-form-field color="accent" fxFlex="50%">
    <mat-label>{{ 'ASSETS.VARIABLE_FORM.SUM_FORM.PLACEHOLDERS.TOLERANCE' | translate }}</mat-label>
    <mat-select formControlName="tolerance" required>
      @for (item of toleranceValues; track item) {
        <mat-option [value]="item">{{ item.value + ' ' + item.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  @for (parameter of formulaParameters; track parameter.name) {
    <shared-iot4bos-asset-ui-variable-parameter
      (clearFormControl)="clearFormControl(parameter.name)"
      [assetVariable]="parametersForm.controls[parameter.name].value"
      [invalidUnitTooltip]="invalidUnitTooltip | translate: { unit: parameter.unit }"
      [isFirstEmptyParameter]="firstEmptyParameter?.name === parameter.name"
      [isTouched]="parametersForm.touched"
      [isUnitInvalid]="parametersForm.controls[parameter.name].errors?.invalidUnit"
      [parameter]="parameter"
      [placeholder]="placeholder + parameter?.name | translate"
      fxFlex
    >
    </shared-iot4bos-asset-ui-variable-parameter>
  }
</form>
