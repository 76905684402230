import { AsyncPipe, CommonModule, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [TranslateModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatOptionModule, ReactiveFormsModule, NgClass, AsyncPipe, CommonModule],
  selector: 'shared-iot4bos-asset-ui-variable-units-autocomplete',
  templateUrl: './variable-units-autocomplete.component.html',
  styleUrls: ['./variable-units-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariableUnitsAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentUnit = '';
  @Input() warning = false;
  @Input() clearUnit = signal(false);

  @Output() selectUnit = new EventEmitter<string>();

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  categoryForm = this.formBuilder.group({ unit: '' });
  unitsByCategory: { name: string; units: string[] }[] = [];
  unitsByCategoryFiltered$!: Observable<{ name: string; units: string[] }[]>;
  currentFilteredCategory: { name: string; units: string[] }[] = [];

  destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private readonly assetVariablesService: AssetVariablesService
  ) {
    effect(
      () => {
        if (this.clearUnit()) {
          this.unitControl.reset();
        }
      },
      { allowSignalWrites: true }
    );
  }

  get unitControl(): AbstractControl {
    return this.categoryForm?.get('unit') as UntypedFormControl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.currentUnit && this.unitsByCategory.length) {
      this.setUnitControlAndSendValue(changes.currentUnit.currentValue);
    }
  }

  ngOnInit(): void {
    this.assetVariablesService
      .getUnits()
      .pipe(takeUntil(this.destroy$))
      .subscribe((units) => {
        this.unitsByCategory = units;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.unitsByCategoryFiltered$ = this.categoryForm.get('unit').valueChanges.pipe(
          startWith(this.currentUnit ?? ''),
          map((value) => {
            this.currentFilteredCategory = this.filterUnits(value || '');
            return this.currentFilteredCategory;
          })
        );
        this.setUnitControlAndSendValue(this.currentUnit);
      });

    this.unitControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.selectUnit.emit();
      this.clearUnit.set(false);
    });
  }

  setUnitControlAndSendValue(currentUnit: string): void {
    if (currentUnit) {
      this.unitControl.setValue(
        this.unitsByCategory.reduce((acc, category) => {
          const matchingUnit = category.units.find((unit) => unit === currentUnit);
          if (matchingUnit) {
            acc = matchingUnit;
          }
          return acc;
        }, '')
      );
      this.selectUnit.emit(this.unitControl.value);
    }
  }

  filterUnits(value: string): { name: string; units: string[] }[] {
    if (value) {
      return this.unitsByCategory
        .map((category: { name: string; units: string[] }) => ({ name: category.name, units: this.getMatchingUnits(category.units, value) }))
        .filter((category: { name: string; units: string[] }) => category.units.length > 0);
    }

    return this.unitsByCategory;
  }

  getMatchingUnits(units: string[], value: string): string[] {
    return units.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectUnit.emit(event.option.value);
  }

  autocompleteOnEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.currentFilteredCategory.length === 1 && this.currentFilteredCategory[0].units.length === 1) {
      this.unitControl.setValue(this.currentFilteredCategory[0].units[0]);
      this.selectUnit.emit(this.unitControl.value);
      this.autocomplete.closePanel();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
