<header class="iot-platform-ui-header" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
  <button
    (click)="onToggleMenuClick()"
    [matTooltipClass]="'regular-tooltip'"
    [matTooltip]="'HEADER.TOGGLE_NAVIGATION_TOOLTIP' | translate"
    class="regular-round-button"
    mat-icon-button
  >
    <mat-icon>menu</mat-icon>
  </button>
  <img (click)="onLogoClick()" [src]="logo()" alt="" class="iot-platform-ui-header__logo" />
  <span class="border-left">{{ 'APP_NAME' | translate }}</span>
  <span class="border-left">{{ selectedProfile()?.entityName }}</span>
  <span fxFlex></span>
  <span class="border-right">{{ timezone() }}</span>
  <mat-select
    (click)="allowSwitchBusinessProfile() ? onBusinessProfileListClicked($event) : $event.preventDefault()"
    (selectionChange)="switchBusinessProfile($event)"
    [disabled]="!allowSwitchBusinessProfile() || userLoading()"
    [value]="selectedProfile()"
    class="navbar-business-profile-select border-right"
  >
    <mat-select-trigger>{{ selectedProfile()?.name }}</mat-select-trigger>
    @for (profile of businessProfiles(); track profile.id) {
      <mat-option [disabled]="profile.notCoveredByUserEntityAt" [value]="profile">
        <span class="bp-list--bp-name">{{ profile.name }}</span>
        @if (profile.notCoveredByUserEntityAt) {
          <mat-icon [matTooltip]="'HEADER.BUSINESS_PROFILE_OUT_OF_SCOPE' | translate" class="bp-list--info-icon"
                    matTooltipClass="regular-tooltip"
          >info_outlined
          </mat-icon>
        }
      </mat-option>
    }
  </mat-select>
  <button [matMenuTriggerFor]="menu" class="user-button" color="accent" mat-mini-fab>
    <p data-hj-suppress>{{ initials() }}</p>
  </button>
  <mat-menu #menu="matMenu" fxLayout="row">
    @if (allowRedirectToMyProfile()) {
      <button (click)="onMyProfileClick()" mat-menu-item>{{ 'HEADER.MY_PROFILE' | translate }}</button>
      <mat-divider></mat-divider>
    }
    <button (click)="onLogout()" mat-menu-item>{{ 'HEADER.LOGOUT' | translate }}</button>
  </mat-menu>
</header>
