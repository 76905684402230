import { Component, DestroyRef, inject, Injector, Input, model, output } from '@angular/core';
import { Filter } from '@iot-platform/models/common';
import { MAX_FILTERS } from '../filter-engine.component';

@Component({
  template: ''
})
export abstract class AbstractFilterEngineFieldComponent<T> {
  data = model<T>();
  currentFilters = model<Filter[]>();
  currentFiltersSize = model<number>(0);
  maxFilters = model<number>(MAX_FILTERS);

  dispatchFilterValue = output<Filter | Filter[]>();
  @Input() onDispatchFilterValue!: (filters: Filter | Filter[]) => void;

  protected injector: Injector = inject(Injector);
  protected destroyRef: DestroyRef = inject(DestroyRef);

  protected dispatchFilterEvent(filters: Filter | Filter[]) {
    this.dispatchFilterValue.emit(filters);
    this.onDispatchFilterValue?.(filters);
  }
}
