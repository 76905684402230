@if (cellOptions?.mode === 'checkbox') {
  <mat-checkbox [(ngModel)]="data" color="accent" [disabled]="cellOptions.disabled"></mat-checkbox>
} @else if (data) {
  <div [ngClass]="{ 'translated-cell': true, withStatusPadding: this.params.cellOptions?.withStatusPadding }" [ngStyle]="translateStyle">
    <span>
      {{ translateKey + (cellOptions?.applyLowerCase ? data.toLowerCase() : data) | translate }}
    </span>
  </div>
} @else {
  <div class="translated-cell" [ngStyle]="translateStyle">-</div>
}
