import { FavoriteView, Filter, TagCategory } from '@iot-platform/models/common';
import { CommandType, Device, DeviceCallLog, DeviceVariable, I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DevicesActions = createActionGroup({
  source: 'Devices',
  events: {
    'Load Device By Id': props<{ deviceId: string }>(),
    'Load Device By Id Success': props<{ device: Device }>(),
    'Load Device By Id Failure': props<{ error: unknown }>(),

    'Load Device Site': props<{ siteId: string }>(),
    'Load Device Site Success': props<{ site: Site }>(),
    'Load Device Site Failure': props<{ error: unknown }>(),

    'Select Device': props<{ device: Device }>(),
    'Select Device Success': props<{ device: Device }>(),
    'Select Device Failure': props<{ error: unknown }>(),

    'Update Device': props<{ device: Device }>(),
    'Update Device Success': props<{ device: Device }>(),
    'Update Device Failure': props<{ error: unknown }>(),

    'Move Devices': props<{ devices: Device[]; originSite?: Site }>(),
    'Move Devices Success': props<{ devices: Device[]; currentSite: Site; originSite?: Site }>(),
    'Move Devices Failure': props<{ errors: unknown }>(),

    'Activate Device': props<{ device: Device }>(),
    'Activate Device Success': props<{ device: Device }>(),
    'Activate Device Failure': props<{ error: unknown }>(),

    'Reset Device': props<{ device: Device }>(),
    'Reset Device Success': props<{ device: Device }>(),
    'Reset Device Failure': props<{ error: unknown }>(),

    'Send Command': props<{ device: Device; command: { command: CommandType } }>(),
    'Send Command Success': emptyProps(),
    'Send Command Failure': props<{ error: unknown }>(),

    'Bulk Send Command': props<{ devicesIds: string[]; command: { command: CommandType } }>(),
    'Bulk Send Command Success': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Send Command Failure': props<{ error: unknown }>(),

    'Delete Device': props<{ device: Device }>(),
    'Delete Device Success': props<{ device: Device }>(),
    'Delete Device Failure': props<{ error: unknown }>(),

    'Load Device Tags': props<{ deviceId: string }>(),
    'Load Device Tags Success': props<{ tags: TagCategory[] }>(),
    'Load Device Tags Failure': props<{ error: unknown }>(),

    'Update Device Tags': props<{ deviceId: string; tags: TagCategory[] }>(),
    'Update Device Tags Success': props<{ tags: TagCategory[] }>(),
    'Update Device Tags Failure': props<{ error: unknown }>(),

    'Bulk Add Device Tag': props<{ tagLabelId: string; devicesIds: string[] }>(),
    'Bulk Add Device Tag Success': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Add Device Tag Failure': props<{ error: unknown }>(),

    'Bulk Remove Device Tag': props<{ tagLabelId: string; devicesIds: string[] }>(),
    'Bulk Remove Device Tag Success': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Remove Device Tag Failure': props<{ error: unknown }>(),

    'Load Device Variables': props<{ deviceId: string }>(),
    'Load Device Variables Success': props<{ deviceVariables: DeviceVariable[] }>(),
    'Load Device Variables Failure': props<{ error: unknown }>(),

    'Delete Device Variables': props<{ deviceVariables: DeviceVariable[] }>(),
    'Delete Device Variables Success': props<{ response: I4BBulkOperationApiResponse }>(),
    'Delete Device Variables Failure': props<{ error: unknown }>(),

    'Reset Device Variables Last Values': props<{ deviceVariables: DeviceVariable[] }>(),
    'Reset Device Variables Last Values Success': props<{ response: I4BBulkOperationApiResponse }>(),
    'Reset Device Variables Last Values Failure': props<{ error: unknown }>(),

    'Load Device Call Logs': props<{ deviceId: string }>(),
    'Load Device Call Logs Success': props<{ callLogs: DeviceCallLog[] }>(),
    'Load Device Call Logs Failure': props<{ error: unknown }>(),

    'Save Current Favorite View': props<{ currentFavoriteView: FavoriteView }>(),
    'Set Filters': props<{ filters: Filter[] }>()
  }
});
