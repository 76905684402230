import {
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  Injector,
  Input,
  input,
  OnInit,
  output,
  Signal,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';

/* eslint-disable no-underscore-dangle */
@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinner,
    MatTooltip
  ],
  selector: 'iot-platform-ui-async-autocomplete',
  templateUrl: './async-autocomplete.component.html',
  styleUrls: ['./async-autocomplete.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'mat-mdc-select-bp-overlay-pane' }
    }
  ]
})
export class AsyncAutocompleteComponent implements OnInit {
  showSpinner = input<boolean>(false);
  _showSpinner: WritableSignal<boolean> = signal(false);
  displaySearchIcon = input<boolean>(true);
  data = input<any[] | undefined>([]);
  debounceTime = input<number>(300);
  minLength = input<number>(3);
  @Input() displayWrapper!: (item: any) => string;
  displayKey = input<string>('id');
  placeholder = input<string>('');
  autocomplete = input<boolean>(true);
  clearOnSelect = input<boolean>(false);
  initialItem = input<any>();
  standaloneMode = input<boolean>(false);
  filterKey = input<string>('');
  hintMessage = input<string>('');
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  errorMessage = input<string>();
  tooltip = input<boolean>(false);

  search = output<string>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  reset = output<void>();
  selectionChanged = output<any>();

  searchForm = new FormGroup<{ searchKey: FormControl<string> }>({
    searchKey: new FormControl<string>('')
  });
  filteredData: WritableSignal<any[]> = signal([]);

  dataToDisplay: Signal<any[]> = computed(() => {
    const standaloneMode = this.standaloneMode();
    const filteredData = this.filteredData();
    const data = this.data();
    if (standaloneMode) {
      return filteredData;
    }
    return data;
  });

  @ViewChild('inputAutoComplete') inputAutoComplete: any;

  protected readonly injector: Injector = inject(Injector);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    this.onShowSpinnerChangedEffect();
    this.onDisabledChangedEffect();
    this.onRequiredChangedEffect();
  }

  get control(): AbstractControl {
    return this.searchForm.get('searchKey') as FormControl;
  }

  ngOnInit(): void {
    this.onSearchTermValueChanged();
    this.initInitialItemEffect();
  }

  initFilteredData() {
    const initialItem = this.initialItem();
    const filterKey = this.filterKey();
    const initialSearchString = initialItem ? initialItem[filterKey] : '';
    this.control.valueChanges
      .pipe(
        startWith(initialSearchString),
        map((value) => this.filterValue(value)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((values) => {
        this.filteredData.set(values);
      });
  }

  filterValue(value: any): any[] {
    let filterValue = '';
    const filterKey = this.filterKey();
    if (value) {
      if (filterKey) {
        filterValue = typeof value === 'string' ? value.toLowerCase() : get(value, [filterKey], '')?.toLowerCase?.();
      } else {
        filterValue = typeof value === 'string' ? value.toLowerCase() : JSON.stringify(value)?.toLowerCase?.();
      }
    }
    return this.data()?.filter((option) => {
      if (filterKey) {
        return get(option, [filterKey], '')?.toLowerCase?.()?.includes?.(filterValue);
      } else {
        return JSON.stringify(option)?.toLowerCase()?.includes?.(filterValue);
      }
    });
  }

  defaultDisplayWrapper = (item: any): string => {
    const displayKey = this.displayKey();
    return item && displayKey ? get(item, [displayKey]) : item;
  };

  onOptionSelected(event: any): void {
    const {
      option: { value }
    } = event;
    this.selectionChanged.emit(value);
    if (this.clearOnSelect()) {
      this.control.reset();
    }
  }

  resetControl(event: any): void {
    event.stopPropagation();
    this._showSpinner.set(false);
    this.control.setValue('');
    this.reset.emit();
    this.inputAutoComplete?.nativeElement.focus();
  }

  private onShowSpinnerChangedEffect() {
    effect(
      () => {
        const showSpinner = this.showSpinner();
        this._showSpinner.set(showSpinner);
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  private onDisabledChangedEffect() {
    effect(
      () => {
        const disabled = this.disabled();
        if (disabled) {
          this.control?.disable();
        } else {
          this.control?.enable();
        }
      },
      { injector: this.injector }
    );
  }

  private onRequiredChangedEffect() {
    effect(
      () => {
        const required = this.required();
        if (required) {
          this.control?.setValidators([Validators.required]);
        } else {
          this.control?.setValidators([]);
        }
        this.control?.updateValueAndValidity();
      },
      { injector: this.injector }
    );
  }

  private onSearchTermValueChanged() {
    if (this.standaloneMode()) {
      this.initFilteredData();
      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((term: string) => {
        this.search.emit(term);
      });
    } else {
      this.control.valueChanges
        .pipe(
          debounceTime(this.debounceTime()),
          filter((term) => typeof term === 'string'),
          tap((term: string) => {
            if (this.control.touched && (term === null || term === undefined || !term.length || term.length < this.minLength())) {
              this.reset.emit();
            }
          }),
          filter((term: string) => !!term && term.length >= this.minLength()),
          distinctUntilChanged(),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((term: string) => {
          this.search.emit(term);
        });
    }
  }

  private initInitialItemEffect() {
    effect(
      () => {
        const initialItem = this.initialItem();
        this.control?.setValue(initialItem);
        if (initialItem !== undefined) {
          this.selectionChanged.emit(initialItem);
        }
      },
      { injector: this.injector }
    );
  }
}
