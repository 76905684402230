<form [formGroup]="parametersForm" fxLayout="column" fxLayoutGap="4px" style="margin-top: 10px">
  @for (parameter of formulaParameters; track parameter.name) {
    <div>
      <shared-iot4bos-asset-ui-variable-parameter
        fxFlex
        [assetVariable]="parametersForm.controls[parameter.name].value"
        [parameter]="parameter"
        [isUnitInvalid]="parametersForm.controls[parameter.name].errors?.invalidUnit"
        [isTouched]="parametersForm.touched"
        [isFirstEmptyParameter]="firstEmptyParameter?.name === parameter.name"
        [invalidUnitTooltip]="invalidUnitTooltip | translate: { unit: parameter.unit }"
        [placeholder]="placeholder + parameter?.name | translate"
        (clearFormControl)="clearFormControl(parameter.name)"
      >
      </shared-iot4bos-asset-ui-variable-parameter>
    </div>
  }
</form>
