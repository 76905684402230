import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { DetailPopupCardsModule, UiModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, UserNamePipe } from '@iot-platform/pipes';
import { SharedModule, VariableChartModule, VariableChartService } from '@iot-platform/shared';
import { FavoriteViewsModule } from '@iot-platform/shared/components';
import { CommentsPanelComponent } from '@iot-platform/shared/iot4bos-ui';
import { DeviceEventsService } from '@iot-platform/shared/services';
import { TableEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EventDetailPopupModule } from '../../components/event-detail-popup/event-detail-popup.module';
import { MvCellModule } from '../../components/mv-cell/mv-cell.module';
import { DeviceEventsApi } from './+state/device-events.api';
import { DeviceEventsCommentsEffects } from './+state/effects/device-events-comments.effects';
import { DeviceEventsEffects } from './+state/effects/device-events.effects';
import * as fromDeviceEvents from './+state/reducers';
import { DeviceEventsShellComponent } from './containers/device-events-shell/device-events-shell.component';

const deviceEventsRoutes: Routes = [{ path: '', component: DeviceEventsShellComponent }];

@NgModule({
  declarations: [DeviceEventsShellComponent],
  exports: [RouterModule],
  imports: [
    SharedModule,
    UiModule,
    MvCellModule,
    DetailPopupCardsModule,
    StoreModule.forFeature(fromDeviceEvents.deviceEventsFeatureKey, fromDeviceEvents.reducers),
    EffectsModule.forFeature([DeviceEventsEffects, DeviceEventsCommentsEffects]),
    RouterModule.forChild(deviceEventsRoutes),
    FavoriteViewsModule,
    VariableChartModule,
    TableEngineModule,
    EventDetailPopupModule,
    GridEngineModule,
    CommentsPanelComponent
  ],
  providers: [DeviceEventsService, DeviceEventsApi, VariableChartService, DateFormatPipe, UserNamePipe]
})
export class DeviceEventsModule {}
