<form [formGroup]="form" class="multiple-inputs-container" fxLayout="row wrap" fxLayoutAlign="start center"
      fxLayoutGap="30px">
  @for (input of inputs(); track $index) {
    <div fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field class="input-field" color="accent">
        <mat-label>{{ input.placeholder | translate }}</mat-label>
        <input (keyup.enter)="onInputValidation(input.criteriaKey)" [formControlName]="input.criteriaKey" matInput />
      </mat-form-field>
      <button
        (click)="onInputValidation(input.criteriaKey)"
        [disabled]="!form.get(input.criteriaKey).value"
        [ngClass]="{ 'add-button-on': form.get(input.criteriaKey).value }"
        class="add-button-off"
        color="accent"
        mat-mini-fab
        type="button"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  }
</form>
