import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  selector: 'shared-iot4bos-ui-edit-comment-dialog',
  templateUrl: './edit-comment-dialog.component.html',
  styleUrls: ['./edit-comment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCommentDialogComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({});

  public dialogRef: MatDialogRef<EditCommentDialogComponent> = inject(MatDialogRef<EditCommentDialogComponent>);
  public data: { comment: string } = inject(MAT_DIALOG_DATA);

  get comment(): AbstractControl {
    return this.form.get('comment');
  }

  ngOnInit(): void {
    this.initForm();
  }

  save(): void {
    this.dialogRef.close(this.comment.getRawValue().trim());
  }

  close(): void {
    this.dialogRef.close();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl(this.data.comment, [Validators.required, Validators.maxLength(300)])
    });
  }
}
