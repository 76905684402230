@if (data()?.multiSelect) {
  <iot-platform-ui-timezone-autocomplete-multiple-selects
    (selectionChange)="onSelectionChange($event)"
    [currentFilters]="currentFilters()"
    [currentFiltersSize]="currentFiltersSize()"
    [data]="data()"
    [maxFilters]="maxFilters()"
  />
} @else {
  <iot-platform-ui-timezone-autocomplete (selectionChange)="onSelectionChange($event)" />
}
