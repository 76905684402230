import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { DeviceInfoStatusModule, DeviceInfoToolbarComponent, DeviceLatestCommandStatusComponent, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, InfoDisplayPipe, LinkifyPipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { DeviceStatusService } from '@iot-platform/shared/services';
import { TableEngineModule } from '@iot-platform/table-engine';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { MasterViewCellModule } from '../../../../../../../table-engine/src/lib/components/table/cells/master-view-cell.module';
import { EventsByConceptGridModule } from '../../../../components/events-by-concept-grid/events-by-concept-grid.module';
import { DeviceCallLogModule } from '../../components/device-call-log/device-call-log.module';
import { DeviceCardComponent } from '../../components/device-card/device-card.component';
import { DeviceEventsTabComponent } from '../../components/device-events-tab/device-events-tab.component';
import { DeviceInfoFormComponent } from '../../components/device-info-form/device-info-form.component';
import { DeviceInfoComponent } from '../../components/device-info/device-info.component';
import { DeviceVariablesTableComponent } from '../../components/device-variables-table/device-variables-table.component';
import { DeviceOverviewComponent } from './device-overview.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    IotPlatformUiModule,
    TranslateModule,
    DeviceCallLogModule,
    TableEngineModule,
    HighchartsChartModule,
    MasterViewCellModule,
    DeviceInfoToolbarComponent,
    GridEngineModule,
    DeviceInfoStatusModule,
    DeviceLatestCommandStatusComponent,
    EventsByConceptGridModule,
    InfoDisplayPipe,
    DateFormatPipe,
    LinkifyPipe
  ],
  declarations: [
    DeviceOverviewComponent,
    DeviceInfoComponent,
    DeviceInfoFormComponent,
    DeviceVariablesTableComponent,
    DeviceCardComponent,
    DeviceEventsTabComponent
  ],
  exports: [DeviceOverviewComponent, DeviceInfoComponent, DeviceCardComponent, DeviceVariablesTableComponent, DeviceLatestCommandStatusComponent],
  providers: [DeviceStatusService, DateFormatPipe, TranslateService]
})
export class DeviceOverviewModule {}
