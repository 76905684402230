@if (displayWrapper) {
  <div [formGroup]="searchForm" class="async-autocomplete-multiple-selects"
       data-cy="iot-platform-ui-async-autocomplete">
    <mat-form-field class="full-width form-field" color="accent">
      @if (displaySearchIcon()) {
        <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
      }
      <mat-label>{{ placeholder() | translate }}</mat-label>
      @if (autocomplete()) {
        <input
          #inputAutoComplete
          #trigger="matAutocompleteTrigger"
          [matAutocomplete]="auto"
          [required]="required()"
          data-cy="iot-platform-ui-async-autocomplete-input-field"
          formControlName="searchKey"
          matInput
          type="text"
        />
      } @else {
        <input [required]="required()" data-cy="iot-platform-ui-async-autocomplete-input-field"
               formControlName="searchKey" matInput type="text" />
      }
      @if (!showSpinner() && control?.value && !control?.disabled) {
        <button (click)="resetControl($event)" aria-label="Clear" class="close-btn" mat-icon-button matIconSuffix
                type="button">
          <mat-icon>close</mat-icon>
        </button>
      }
      @if (hintMessage()) {
        <mat-hint>{{ hintMessage() }}</mat-hint>
      }
      @if (showSpinner()) {
        <mat-spinner class="form-field_spinner" color="accent" diameter="16" mode="indeterminate" />
      }
      @if (required() && errorMessage() && control.invalid) {
        <mat-error>{{ errorMessage() | translate }}</mat-error>
      }
      <mat-autocomplete #auto="matAutocomplete"
                        [displayWith]="displayWrapper">
        @if (!showSpinner()) {
          @for (item of dataToDisplay(); track item) {
            <mat-option (click)="$event.stopPropagation();" [value]="selectedFilters()"
                        class="iot-platform-ui-async-autocomplete-option iot-platform-ui-async-autocomplete-option--multiple"
                        data-cy="iot-platform-ui-async-autocomplete-option">
              <mat-checkbox
                (click)="$event.stopPropagation(); toggleSelection(item);"
                [checked]="item.selected"
                [disabled]="filtersSize() >= maxFilters()"
              >
                {{ displayWrapper(item) }}
              </mat-checkbox>
            </mat-option>
          }
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>
} @else {
  <div [formGroup]="searchForm" class="async-autocomplete-multiple-selects"
       data-cy="iot-platform-ui-async-autocomplete">
    <mat-form-field class="full-width form-field" color="accent">
      @if (displaySearchIcon()) {
        <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
      }
      <mat-label>{{ placeholder() | translate }}</mat-label>
      @if (autocomplete()) {
        <input
          #inputAutoComplete
          #trigger="matAutocompleteTrigger"
          [matAutocomplete]="auto"
          [required]="required()"
          data-cy="iot-platform-ui-async-autocomplete-input-field"
          formControlName="searchKey"
          matInput
          type="text"
        />
      } @else {
        <input [required]="required()" data-cy="iot-platform-ui-async-autocomplete-input-field"
               formControlName="searchKey" matInput type="text" />
      }
      @if (!showSpinner() && control?.value && !control?.disabled) {
        <button (click)="resetControl($event)" aria-label="Clear" class="close-btn" mat-icon-button matIconSuffix
                type="button">
          <mat-icon>close</mat-icon>
        </button>
      }
      @if (hintMessage()) {
        <mat-hint>{{ hintMessage() }}</mat-hint>
      }
      @if (showSpinner()) {
        <mat-spinner class="form-field_spinner" color="accent" diameter="16" mode="indeterminate" />
      }
      @if (required() && errorMessage() && control.invalid) {
        <mat-error>{{ errorMessage() | translate }}</mat-error>
      }
      <mat-autocomplete #auto="matAutocomplete"
                        [displayWith]="defaultDisplayWrapper">
        @if (!showSpinner()) {
          @for (item of dataToDisplay(); track item) {
            <mat-option (click)="$event.stopPropagation();" [value]="selectedFilters()"
                        class="iot-platform-ui-async-autocomplete-option iot-platform-ui-async-autocomplete-option--multiple"
                        data-cy="iot-platform-ui-async-autocomplete-option">
              <mat-checkbox
                (click)="$event.stopPropagation(); toggleSelection(item);"
                [checked]="item.selected"
                [disabled]="filtersSize() >= maxFilters()"
              >
                {{ defaultDisplayWrapper(item) }}
              </mat-checkbox>
            </mat-option>
          }
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>
}
