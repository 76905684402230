import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IconModule } from '@iot-platform/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncAutocompleteMultipleSelectsModule } from './components/async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.module';
import { AsyncAutocompleteModule } from './components/async-autocomplete/async-autocomplete.module';
import { BreadcrumbItemModule } from './components/breadcrumb-item/breadcrumb-item.module';
import { BreadcrumbModule } from './components/breadcrumb/breadcrumb.module';
import { BuildInfoComponent } from './components/build-info';
import { CardLoaderModule } from './components/card-loader/card-loader.module';
import { ChipModule } from './components/chip/chip.module';
import { ColorPickerModule } from './components/color-picker/color-picker.module';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ContactFormModule } from './components/contact-form/contact-form.module';
import { CountryAutocompleteModule } from './components/country-autocomplete/country-autocomplete.module';
import { DetailPopupCardsModule } from './components/detail-popup-cards/detail-popup-cards.module';
import { DetailPopupModule } from './components/detail-popup/detail-popup.module';
import { DeviceDetailsModule } from './components/device-details/device-details.module';
import { EventSeverityDisplayModule } from './components/event-severity-display/event-severity-display.module';
import { FilterEngineModule } from './components/filter-engine';
import { ImageSelectorModule } from './components/image-selector/image-selector.module';
import { ManageTagsFormComponent } from './components/tags/manage-tags-form/manage-tags-form.component';
import { PanelComponent } from './components/panel';
import { PopupModule } from './components/popup/popup.module';
import { ProgressBarButtonModule } from './components/progress-bar-button/progress-bar-button.module';
import { ReleaseNotesComponent } from './components/release-notes';
import { SidenavModule } from './components/sidenav';
import { SiteDetailsModule } from './components/site-details/site-details.module';
import { SpinnerBackgroundModule } from './components/spinner-background/spinner-background.module';
import { SubscriptionButtonModule } from './components/subscription-button/subscription-button.module';
import { TagCategoryListByConceptModule } from './components/tags/tag-category-list-by-concept/tag-category-list-by-concept.module';
import { TimezoneAutocompleteMultipleSelectsModule } from './components/timezone-autocomplete-multiple-selects/timezone-autocomplete-multiple-selects.module';
import { TimezoneAutocompleteModule } from './components/timezone-autocomplete/timezone-autocomplete.module';
import { ToolbarV2Module } from './components/toolbar-v2/toolbar-v2.module';
import { YearPickerModule } from './components/year-picker/year-picker.module';

@NgModule({
  imports: [
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatGridListModule,
    MatProgressBarModule,
    FlexLayoutModule,
    CommonModule,
    TranslateModule,
    CardLoaderModule,
    ImageSelectorModule,
    ChipModule,
    IconModule,
    ColorPickerModule,
    FilterEngineModule,
    PopupModule,
    ManageTagsFormComponent,
    TagCategoryListByConceptModule,
    ProgressBarButtonModule,
    SpinnerBackgroundModule,
    MatProgressSpinnerModule,
    ReleaseNotesComponent,
    DetailPopupModule,
    EventSeverityDisplayModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    BreadcrumbModule,
    BreadcrumbItemModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    SubscriptionButtonModule,
    ToolbarV2Module,
    SiteDetailsModule,
    DetailPopupCardsModule,
    ContactCardComponent,
    ContactFormModule,
    BuildInfoComponent,
    SidenavModule,
    PanelComponent,
    YearPickerModule
  ],
  exports: [
    ImageSelectorModule,
    CardLoaderModule,
    IconModule,
    ColorPickerModule,
    FilterEngineModule,
    ChipModule,
    PopupModule,
    ManageTagsFormComponent,
    TagCategoryListByConceptModule,
    ProgressBarButtonModule,
    SpinnerBackgroundModule,
    ReleaseNotesComponent,
    DetailPopupModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    BreadcrumbItemModule,
    SubscriptionButtonModule,
    ToolbarV2Module,
    SiteDetailsModule,
    DeviceDetailsModule,
    DetailPopupCardsModule,
    YearPickerModule,
    ContactCardComponent,
    ContactFormModule,
    BuildInfoComponent,
    SidenavModule,
    PanelComponent
  ]
})
export class UiModule {}
